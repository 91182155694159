<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="2">
        <h3>Menu</h3>
        <h4>Pro závodníky</h4>
        <div><a href="#automodul">Automodul</a></div>
        <div><a href="#prihlasky">Přihlášky</a></div>
        <div><a href="#prihlasky-oddilove">Přihlášky členů oddílu</a></div>
        <div><a href="#sprava-celnu">Správa členů oddílu</a></div>
        <div><a href="#registrace-celnu">Registrace členů oddílu</a></div>





        <h4>Pro pořadatele</h4>

        <!-- <div><a href="#edit_vse">Editace přihlášených</a></div> -->
        <div><a href="#livelox">Livelox (postupy)</a></div>
        <!-- <div><a href="#prihlasky-nastaveni">Nastavení přihlášek</a></div> -->
        <div><a href="#opravneni">Uživ. oprávnění</a></div>


         <h4>Pro vývojáře</h4>
        <div><a href="#obecne">Obecné informace</a></div>
        <div><a href="#si_driod">SI Droid Event</a></div>
        <div><a href="#seznamy">Seznam závodníků</a></div>
        <div><a href="#startovka">Startovka</a></div>
        <div><a href="#vysledky">Výsledky</a></div>

      </v-col>
      <v-col cols="12" md="10">
        <h3 id="autoodul">Automodul</h3>
        <p>Automodul slouží k rozdělování lidí do dopravy. Minimální počet míst je: 1, maximální počet míst je 50. Dobrá praxe pro nejsnadnější kooperaci je taková, že všichni lidé s možností vzít auto jej založí. Zbytek se do aut přihlásí. Pokud je aut nedostatek nebo přebytek, je potřeba, aby se účastníci domluvili a počet vyvážili.</p>
        <h4>Funkce automodulu:</h4>
        <p>První pole je pole řidiče. Na něj půjde vyúčtování cestovného, neboť se předpokládá, že řidič je i vlastník auta a kupující pohonných hmot. Editovat dopravní prostředek a řidiče může pouze řidič sám a správce oddílu. Pokud snížíte počet míst v automodulu, smažou se i přihlášení cestujících a to odzadu. Nelze smazat místa při přítomnosti jednosměrných cest. Příjmení a Jméno vkládejte v tomto pořadí a ve správném formátu ať se data správně spárují. Pokud jsi přihlášen, pole se vyplní automaticky. Pokud oddíl využívá vyúčtování cestovného, doplňte potřebné údaje v editaci automodulu.</p>

        <!-- <h3 id="edit_vse">Editace přihlášených (jen pořadatel a předseda oddílu)</h3>
        <p>Pokud se u přihlášených objeví tento znak: <img :src="`${BaseUrl}/static/attention.png`" alt="neautorizovaný závodník" width="25px">, jedná se o přihlášku, která nebyla vytvořena přihlášeným uživatelem a je potřeba zkontrolovat, jestli jsou údaje v ní vyplněny správně.</p> -->

        
        <!-- <h3 id="prihlasky-nastaveni">Nastavení přihlášek (Po rozkliknutí soutěže v pravém sloupci)</h3>
        <ul>
            <li>Ceny a procenta se udávají v číslech bez znaku Kč, %, nebo jiného textu.</li>
            <li>Pokud nezadáte procentuální navýšení ceny ve druhém termínu přihlášek a uvedli jste datum druhého termínu, bude se vždy počítat cena jako při prvním termínu.</li>
            <li>Pokud chcete umožnit půjčit čip bez poplatku, zadejte cenu půjčení čipu 0. Jinak se závodník nemůže přihlásit, aniž by neměl vyplněné číslo čipu.</li>
            <li>K dispozici jsou možnosti 4 typů ubytování, 4 typů obědů a večeří.</li>
            <li>Pro ubytování a stravu se dají napsat názvy sloupců, aby v tom měl přihlašující se závodník přehled. U jídel je nutné ještě vybrat, o jaké jídlo se jedná.</li>
        </ul> -->

        <h3 id="prihlasky">Přihlášky na soutěž</h3>
        <h4>Neregistrovaný uživatel</h4>
        <p>Po rozkliknutí události klikni na přihlásit se a vyplň údaje. Přihlášku po odeslíní nemůžeš editovat, proto si prosím před odesláním přihlášky pečlivě zkontroluj vyplněné údaje. Změnit údaje může jen organizátor události.</p>
        <h4>Registrovaný uživatel</h4>
        <p>Po rozkliknutí události klikni na přihlásit se. Osobní údaje se ti automaticky předvyplní podle nastavení v účtu. Přihlášku můžeš editovat i po odeslání.</p>
        <h4>Přihlašování členů rodiny</h4>
        <p>Závodník (i správce oddílu) si může ve svém nastavení vyplnit správce mých přihlášek, který jej může na soutěže přihlašovat. Oprávněný závodník má poté právo přihlašovat tento účet na soutěže a poté i editovat jeho údaje v soutěži do vypršení termínu přihlášek. <br>
          <a :href="`${BaseUrl}static/assist_user.png`" target="_blank"><img :src="`${BaseUrl}static/assist_user.png`" alt="pole pro vyplnění ID účtu" width="300px"></a>
        </p>


        <h3 id="prihlasky-oddilove">Přihlášky členů oddílu na soutěž pro správce oddílu</h3>
        <p>Manipulace s přihláškami je pro správce oddílu možná v přihlašování na soutěž. V rozevíracím seznamu se nejdříve řadí přihláška přihlášeného uživatele, poté přihlášky těch účtů, které má dovolené přihlášený uživatel upravovat, až poté se zobrazí seznam členů oddílu vzestupně podle příjmení.</p>

        <h3 id="sprava-celnu">Správa členů oddílu</h3>
        <p>Pokud je uživatel veden jako svého oddílu, může měnit pravomoce (organizátor a správce oddílu) a spravovat členy v oddíle. Správa členů se děje prostřednictvím tlačítek v posledním sloupci tabulky Správa:</p> <a :href="`${BaseUrl}static/predseda_edit.png`" target="_blank"><img :src="`${BaseUrl}static/predseda_edit.png`" alt="ikony akce pro editaci členů oddílu" width="100px"></a>
          <li><b>měnit údaje členům oddílu</b> (jméno, příjmení, email, index, číslo SI, rok narození, pohlaví),</li> 
          <li><b>vyloučit uživatele z oddílu</b> (odebrat jim index a tím příslušnost k oddílu),</li> 
          <li><b>smazat účty</b>, které jsou mladší jak 14 dní.</li>

          <h3 id="registrace-celnu">Registrace členů oddílu</h3>
        <p>Registrovat nové uživatele může pouze správce oddílu a to na stránce svého oddílu. Pro registraci kompletní členské základny je vhodná hromadná registrace. Na každý řádek je nutné vložit povinné parametry přesně ve formátu: Příjmení;Jméno;index;email;rok narození;pohlaví;číslo SI. Pohlaví nabývá hodnot: <b>M</b> - muž, <b>F</b> - žena. <b>Email</b> a <b>číslo SI</b> není povinné. Např. Novotný;Petr;XXX8501;;1985;M; Zde není email a poslední parametr (číslo SI) uveden a člen se registruje bez emailu a čísla SI. <b>Pokud uživatel nemá vyplněný email, nemůže se do robisu přihlásit!</b> Oddělení parametrů musí být středníkem ; Nejlépe si tato data nachystáte v excelu. Každému uživateli bez vyplněného emailu při registraci se automaticky vygeneruje email ve tvaru index@rob-is.cz, protože ROBis neumí pracovat s uživatelem bez emailu.</p>
        <p>
          Pokud <b>mladší členové</b> nemají email, tak jej nevyplňujte. Email je pro každý účet totiž unikátní a tak nelze vyplnit email rodičů, kteří mají v robisu také svůj účet. Pokud rodiče účet v robisu nemají, tak jej samozřejmě vyplnit můžete. Na účet bez emailu se nebude dát přihlásit, protože heslo si uživatel vytváří právě přes email. <b>Vedoucí oddílu</b> ale může přes správu členů v oddíle <b>nastavit pro tyto uživatele účet</b>, který je bude moci přes sebe v robisu na soutěže přihlašovat. Takto mohou rodiče přes sebe přihlašovat své děti. Jakmile si mladý člen založí svůj email, vedoucí oddílu tento email uživateli zapíše a bude se moci na účet běžně přihlásit.<br> 
          <a :href="`${BaseUrl}static/club_person_assist_user_edit.png`" target="_blank"><img :src="`${BaseUrl}static/club_person_assist_user_edit.png`" alt="přidání uživatle pro povolení editace jiného účtu" width="600px"></a>
        </p>
        <p>
          Pokud při registraci <b>není vyplněný email</b>, automaticky se uživateli email vytvoří ve formátu index@rob-is.cz. Tento email však není pro uživatele použitelný a řeší jen technické otázky v ROBISu. Vedoucí oddílu jej může kdykoli změnit. 
        </p>
          


      
        <h3 id="opravneni">Uživatelská oprávnění</h3>
        <p>Barevně jsou rozlišeny možné role uživatelů robisu. Stejnou funkci může mít více uživatelů.</p>
        <h4>Předseda oddílu</h4>
        <ul>
            <li>Určuje uživatele pro oprávnění <span style="color:rgb(255, 123, 0)">organizátor</span> a <span style="color:rgb(255, 123, 0)">vedoucí oddílu</span>.</li>
            <li>Předseda může: <b>spravovat údaje členům oddílu</b> (jméno, příjmení, email, index, číslo SI, rok narození, pohlaví), <b>vyloučit uživatele z oddílu</b> (odebrat jim index a příslušnost k oddílu), <b>smazat účty</b>, které jsou mladší jak 14 dní.</li>
            <li>Předseda může být v každém oddíle pouze jeden.</li>
        </ul>

        <h4>Správce oddílu</h4>
        <ul>
            <li>Vytváří registrace nových členů oddílu</li>
            <li>Může vytvářet nebo editovat přihlášky členů oddílu na soutěže</li>

            <!-- <li>Může spravovat automoduly v soutěži.</li>
            <li>Má k dispozici seznam lidí z oddílu přihlášených v jakékoli soutěži a může jej editovat, nebo další lidi na soutěž přihlásit.</li> -->
        </ul>
        <h4>Organizátor</h4>
        <ul>
            <li>Zakládá soutěže pod hlavičkou svého oddílu</li>
            <li>Má přístup ke všem funkcím v soutěžích pořádaných jeho oddílem</li>
        </ul>

        <h3 id="livelox">Livelox (postupy)</h3>
        <h4>Závodník</h4>
        <p>Zaregistruj se na <a href="https://www.livelox.com/">livelox.com</a> a připoj si ve svém účtu aplikaci, ze které se budou nahrávat do liveloxu data nahraná z hodinek. Zatrhni všechny políčka při propojování se službou. Vyber jen jednu aplikaci pro nahrávání, aby se záznamy neduplikovaly. Nastav v účtu soukromí na veřejné.<br>
        Postupy se ti budou k soutěžím nahrávat automaticky po vyčtení hodinek.</p>
        <h4>Pořadatel</h4>
        <p>Zaregistruj se na <a href="https://www.livelox.com/">livelox.com</a>. V menu klikni na spravovat a klikni na přidat akci. Vyplň pole podle potřeby. Dbej zvýšené pozornosti na sekce <b>Čas zveřejnění a Správci</b>, ať se k závodníkům mapa nedostane dříve, než vůbec odjedou na start. <b>Vyhledatelnost nechej odškrtnutou</b>, aby se akce nezobrazovala v kalendáři akcí na liveloxu. Ve správě události vyber editovat mapu. Mapu můžeš vložit nahráním ocad, kmz nebo obrázkového souboru. Doporučuji vložit mapu již se zakreslenými kontrolami. Můžeš vložit i prázdnou mapu a tratě importovat z ocadu ve správě události, ale je to složitější a má to mouchy. V dalším kroku, pokud máš mapu georeferencovanou, zkontroluj, jestli sedí a kdyžtak uprav. Pokud vkládáš obrázek, musíš mapu georeferencovat, aby seděla se skutečností. Doporučuji vytvořit alespoň 3 body. Ulož.</p>
        <p>Nyní je potřeba importovat účastníky, aby livelox dokázal spárovat uživatele s kategorií, jinak se budou muset uživatelé přidávat ručně. Ve správě události v okně výsledky vyber importovat startovku/výsledky. Text záleží na tom, jestli už událost porběhla, nebo ne. Nyní je potřeba nahrát soubor s databází závodníků. Přejdi v ROBISu do příslušné soutěže. Zkontroluj, jestli máš nastavený start 00. Pokud ne, nastav ho, jinak to livelox nevezme. Stáhni si do PC "Data pro livelox". Stažený soubor xml nahraj do liveloxu. Nahrát ho musíš 2 krát, pokud si předtím ručně nevytvořil v události kategorie, což ani nedoporučuji. Poprvé se kategorie vytvoří a po druhém nahrání se livelox kategorie spárují s kategoriemi v xml souboru. Hotovo, uživatelé se po vyčtení jejich hodinek sami do postupů nahrají.</p>
        <p>Nezapomeň vložit url adresu na stránku soutěže v robisu, aby se tam uživatelé dostali. Url by měla vypadat nějak takto: https://www.livelox.com/Events/Show/117784/MCR35MHz.</p>
<br>

        <v-divider
          :thickness="4"
          class="border-opacity-75"
          color="info"
        ></v-divider>

        <h3>Pro vývojáře</h3>
        <span style="color:red">XML a JSON formát zatím není dostupný</span>
        <h3 id="obecne">Obecné informace</h3>
        <p> Robis využívá CSV, XLSX, XML a JSON. Formát a adresy naleznete v menu pod Exporty. XML strukturu vymyslela IOF a <a href="https://github.com/international-orienteering-federation/datastandard-v3" target="_blank">celá dokumentace</a> je k dispozici na <a href="https://orienteering.sport/iof/it/data-standard-3-0/" target="_blank">tomto rozcestí</a>. Výhodou je, že data mohou být poházená v celém souboru zcela náhodně (nezáleží na pořadí hodnot), protože každá hodnota je opatřena jasným identifikátorem, který má definováno, co je hodnota zač. Malou nevýhodou je trochu složitější struktura xml souboru. JSON formát je zjednodušený XML, ve kterém se posílají sice totožná data, ale jeho syntaxe není tak složitá, tím pádem celkový počet přenesených dat je nižší. Je tak vhodný například pro přenos online výsledků. Na standardizaci JSON formátu pro ARDF pracujeme.</p>
        <h3 id="si_driod">SI Driod Event</h3>
        <p>Export záznamů do txt pro <a href="https://play.google.com/store/apps/details?id=se.joja.sidroid.event.full" target="_blank">SI Droid Event</a> je možný jako souhrn všech účtů v robisu (na stránce export) nebo v sekci startovek v nastavení závodu v rozevíracím seznamu export, pouze pro konkrétní závod. Tam je přidána i kategorie, ve ketré závodník v daném závodě běží. Protože hodnty čísla čipu a jména jsou pro SI Droid povinné, záznamy s těmito chybějícími údaji se neexportují.</p>

        <h3 id="seznamy">Seznam závodníků (pouze export)</h3>
        <!--<ul>
            XML vytvořeno pomocí této <a href="https://github.com/international-orienteering-federation/datastandard-v3/blob/master/examples/EntryList1.xml" target="_blank">dokumentace</a>. Ve vzorovém souboru jsou uvedeny všechny hodnoty, které ROBis v seznamu generuje -<a :href="`${BaseUrl}/static/seznam_prihlasenych.xml`" target="_blank">Vzorový seznam závodníků.xml</a><br>
            Poznámky:
            <li>Pokud závodník nemá uvedený čip, chce jej půjčit.</li>
            <li>Neexistuje mezinárodní definice indexu, proto je index uveden v elementu PERSON <code>&lt;ID type="INDEX"&gt;GBM0000&lt;/ID&gt;</code></li>
            <li>Zkratka oddílu je uvedena v elementu ORGANISATION <code>&lt;ShortName&gt;GBM&lt;/ShortName&gt;</code></li>
            <li>Požadavek na start není v IOF definici uveden. Existuje v elementu START <code>&lt;StartRequest&gt;2&lt;/StartRequest&gt;</code> Nabývá hodnot: 0 - první startující, 1 - první skupina, 2 - druhá skupina, 3 - třetí skupina, 4 - poslední startující.</li>-->
        <ul>
          XLSX - slouží pro oddíly a pořadatele pro souhrn informací přihlášených závodnků<br>
          Poznámky:
          <li>Autoriz. = autorizovaný závodník. Je to závodník, který se přihlásil na závod pomocí svého účtu v robisu a jeho údaje jsou tedy ověřené. Např Jméno, příjmení, index apod.</li>
          <li>Startovní skupina - Pokud pořadatel v nastavení soutěže zvolí možnost přihlášek do start. skupin, pak se v přehledu závodů zobrazí takto: 0 - první startující, 1 - první skupina, 2 - druhá skupina, 3- třetí skupina, 4- poslední startující.</li>
        </ul>
        <h3 id="startovka">Startovka (export i import)</h3>
        <ul>
          Poznámky:
          <li>Pokud potřebujete v CSV formátu některý údaj vynechat, nevypisujte mezi ; a ; vůbec nic. Např. 3;Novák;Petr;M20;;11:05:30;GBM8105;OK123;;2045258</li>
          <li>Absolutní čas startu je podporován buď jako <code>hh:mm:ss</code> a nebo jako <code>yyyy-mm-ddThh:mm:ss</code>. S tím, že pokud bude vyplněný pouze první formát, tak se datum do databáze uloží z datumu konání závodu. Může být vyplněné pouze jedno ze startů.</li> 
          <li>Uložení volačky robis nepodporuje</li>
          <li>Stát - podporujeme pouze formát ISO 3166-1 alpha-3, což znamená třípísmenný kód zemí. Např. CZE.</li>
        </ul>
            <!--Vytvořeno pomocí této <a href="https://github.com/international-orienteering-federation/datastandard-v3/blob/master/examples/StartList1.xml" target="_blank">dokumentace</a>.<br>
            Ve vzorovém souboru jsou uvedeny všechny hodnoty, které ROBis v seznamu generuje - <a :href="`${BaseUrl}/static/startlist.xml`" target="_blank">Vzorová startovka.xml</a>
            <li>Pozor, kategorie je nadřazený element pro všechny přihlášené v dané kategorii. Tzn. kategorie závodníka není uvedena u jeho výpisu dat, ale je uvedená pouze jako element, který obsahuje závodníky, kteří do této kategorie spadají.</li><br>
            Povinné údaje pro import startovky do robisu (dodržte strukturu xml souboru viz vzor):
            <li><code>&lt;ID type="ROBIS"&gt;*ID*&lt;/ID&gt;</code> (ID soutěže je například v url adrese) (Nutné vyplňit pouze, pokud startovku NENAHRÁVÁM přes web)</li>
            <li><code>&lt;Time&gt;xx:xx:xx&lt;/Time&gt;</code> (Start 00, pokud se liší od startu v robisu)</li>
            <li><code>&lt;ID type="INDEX"&gt;GBM5030&lt;/ID&gt;</code> (Index závodníka)</li>
            <li><code>&lt;StartTime&gt;xx:xx:xx&lt;/StartTime&gt;</code> (Start závodníka)</li>




      -->
        <h3 id="vysledky">Výsledky (export i import)</h3>

        Robis podporuje hned několik typů importů a exportů v závislosti na typu souboru a zdrojovém softwaru. Kódování UTF-8.<br>
        
                          
                         
        Formáty pro import a jejich struktura:
        <ul>
            <li><b>CSV:</b><br>
              <code>3;Novák;Petr;M20;65:30;11:05:30;GBM8105;OK123;CZE;2045258</code>
            </li>
            <li><b>CSV OCM:</b><br> 
              Robis zpracovává 2 řádky, které se odlišují druhým parametrem. Nabývá hodnot H nebo "" (prázdná hodnota). H - definice vyhledávaných kontrol pro kategorii. "" - výsledek závodníka. Výpis kontrol nebo mezičasů je oddělen čárkou ",". Odlišení jednotlivých kontrol nebo mezičasu je pomocí "#". Typ (kontrol) nabývá hodnot - 0 (standardní), 1 (maják), 2 (divácká). Status (kontroly) nabývá hodnot 0 (OK), 1 (špatná), 2 (neznámá). Více v <a :href="`${BaseUrl}static/OCM_dokumentace_vysledky.jpg`" target="_blank">dokumentaci OCM</a>. Přijímaná struktura definice kontrol a výsledku závodníka:<br>
              <code>Název kategorie;H;Limit;Pásmo;Relativní start;Kontroly: #Typ,Kód,Alias</code><br>
              <code>Název kategorie;;Umístění;Startovní číslo;Příjmení;Jméno;Čas běhu;Počet kontrol;Mezičasy: #Kód,Mezičas,Alias,Typ,Status;Země;Číslo čipu;Zkratka oddílu;Index</code>
            </li>
            <li><b>XML IOF 3.0</b><br>
            </li>
        </ul> 


      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from 'vue';

// base url adresa serveru, odkud se načítají statické obrázky
const BaseUrl = ref(process.env.VUE_APP_API_STATIC_URL);

</script>



<style scoped>

p {
  text-align:justify;  
}

/* Globální styl pro všechny cílové elementy */
[id]:target::before {
  content: "";
  display: block;
  height: 70px; /* Výška odsazení */
  margin-top: -70px; /* Záporná hodnota, aby se obsah posunul nahoru */
  visibility: hidden; /* Skryje pseudoelement, aby nebyl viditelný */
}



h3 {
  margin-bottom: 10px;
  margin-top: 20px;
}

ul {
padding-left: 20px;

}
</style>