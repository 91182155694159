<template>
  <v-container>
    <!-- Poznámky pro soutěž -->
    <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>
    <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
    <v-card v-if="isOrganizer">
      <v-toolbar flat dense>
        <v-tabs v-model="main" class="flex-grow-1" bg-color="primary" slider-color="#F1A829" center-active show-arrows>
          <!-- Zachování původní záložky eventu -->
          <v-tab value="event">{{ event.event_name }}</v-tab>
          <v-divider :thickness="10" class="border-opacity-100" vertical color="#FFFFFF"></v-divider>
          <!-- Dynamické generování záložek pro etapy získané z API a generace obsahu podle Race ID -->
          <v-tab v-for="(race, etap) in races" :key="race.id" :value="`race-${race.id}`">
            E{{ etap + 1 }}: {{ race.race_name }}
          </v-tab>
          <!-- Tlačítko pro přidání další etapy se spuštěním dialogového okna -->
          <v-tooltip location="top center" origin="auto" text="Vytvoř novou Etapu">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" text @click="dialogAddRace = true" color="green" style="height:100%;"><v-icon size="28">mdi-plus-circle</v-icon></v-btn>
            </template>
          </v-tooltip>  
            <!-- Dialog pro přidání Race -->
            <v-dialog v-model="dialogAddRace" persistent max-width="400px">
            <v-card>
              <v-card-title class="text-h5">Přidat novou etapu</v-card-title>
              <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
            <v-card-text>
              <v-form @submit.prevent="addRace">
                <v-text-field v-model="newRace.race_name" label="Název etapy" required :rules="eventTitle"></v-text-field>
                <v-text-field v-model="newRace.race_date" label="Datum" type="date" required :rules="eventDate"></v-text-field>
                <v-select :items="raceLevel" v-model="newRace.race_level" label="Soutěžní úroveň" :rules="eventType"></v-select>
                <v-card-actions>
                <v-btn color="blue darken-1" @click="dialogAddRace = false">Zrušit</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="addRace">Přidat etapu</v-btn>
              </v-card-actions>

              </v-form>
            </v-card-text>
            </v-card>
          </v-dialog>
        </v-tabs>
        <!-- Zachování tlačítka nastavení -->
        <v-btn text :to="'/soutez/' + event.id" style="height:100%;margin-inline-end: 0px"><v-icon size="28">mdi-arrow-left-circle</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-window v-model="main">
          <v-window-item value="event">
            <v-card v-if="event && event.event_organizer">
              <v-form ref="form" @submit.prevent="saveEvent">
              <v-row>
              <!-- První sloupec -->
                <v-col cols="12" md="6">
                  <v-card-title>{{ event.event_name }} <span v-if="event.event_date_start">{{ $formatDate(event.event_date_start) }}</span> <span v-if="event.event_date_end">- {{ $formatDate(event.event_date_end) }}</span></v-card-title>
                  
                    <v-col>Soutěž ID: {{ event.id }}</v-col>
                    <v-text-field label="Název soutěže" v-model="event.event_name" :rules="eventTitle" maxlength="40" counter></v-text-field>
                    <v-text-field v-model="event.event_date_start" label="Datum začátku" type="date" :rules="eventDate"></v-text-field>
                    <v-text-field label="Datum konce" v-model="event.event_date_end" type="date"></v-text-field>
                    <v-text-field label="Start 00" v-if="races.length === 0 & isRace0" v-model="race0.race_startTime" type="time" :value="race0.race_start ? $formatTime(race0.race_start) : ''" @input="updaterace_startTime(race0)" clearable></v-text-field>
                    <v-tooltip location="top center" origin="auto" text="Pokud je vyplněno, vypne se funkce editace této soutěže pro organizátory v oddílu. Editovat toto pole může jen zakladatel soutěže">
                      <template v-slot:activator="{ props }">
                        <v-combobox :readonly="event.author !== Number(authStore.userID)" v-bind="props" v-model="selectedEditors" :items="editors" label="Správci této soutěže" multiple item-value="id" item-title="fullName" :hint="selectedEditors.length === 0 ? 'Nyní mohou soutěž editovat všichni organizátoři v oddílu' : ''" :persistent-hint="selectedEditors.length === 0"></v-combobox>
                      </template>
                    </v-tooltip>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-title color="primary">Další nastavení soutěže</v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <v-select label="Typ soutěže" v-model="event.event_type" :items="eventTypes" :rules="eventType"></v-select>
                          <v-tooltip location="top center" origin="auto" text="Viditelnost určuje zobrazování v kalendáři a na profilu závodníků, pokud jsou na soutěž a etapy přihlášení">
                            <template v-slot:activator="{ props }">
                              <v-select v-bind="props" label="Viditelnost" v-model="event.visibility" :items="visibilityTypes" :rules="visibilityType"></v-select>
                            </template>
                          </v-tooltip>
                          <v-tooltip location="top center" origin="auto" text="Pokud je zrušení aktivní, do osobních kont se nezapočítávají žádné finance a soutěž není započítaná do žebříčků">
                            <template v-slot:activator="{ props }">
                              <v-switch v-bind="props" v-model="event.canceled" label="Nastavit zrušení soutěže" color="primary"></v-switch>
                            </template>
                          </v-tooltip>
                          <v-tooltip location="top center" origin="auto" text="Zkopíruj sem souřadnice např. z mapy.cz. Odkaz se vytvoří automaticky">
                            <template v-slot:activator="{ props }">
                              <v-text-field v-bind="props" label="Souřadnice shromaždiště" v-model="event.event_location"></v-text-field>
                            </template>
                          </v-tooltip>
                          <v-text-field label="Kontakt na organizátora" v-model="event.contact"></v-text-field>
                          <v-text-field label="Zpráva - informace" v-model="race0.message_info" maxlength="500" counter></v-text-field>
                          <v-text-field label="Zpráva - varování" v-model="race0.message_warning" maxlength="500" counter></v-text-field>
                          <v-textarea label="Poznámka" v-model="event.event_note" maxlength="1000" counter></v-textarea>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  

                    <!-- Dialog pro potvrzení smazání Eventu -->
                    <v-dialog v-model="dialogDeleteEvent" persistent max-width="600px">
                      <v-card>
                        <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
                        <v-card-text>Chcete opravdu smazat tuto soutěž <b>{{event.event_name}}</b>? Tato akce je nevratná a smaže i všechny etapy a data!!!</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="dialogDeleteEvent = false">Zrušit</v-btn>
                          <v-btn color="red darken-1" text @click="deleteEvent">Smazat</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                </v-col>

                  <!-- Druhý sloupec -->
                  <v-col cols="12" md="3">
                    <v-card-title>Nastavení přihlašování</v-card-title>
                    <v-tooltip location="top center" origin="auto" text="Bez povolení se nezobrazí možnost přihlásit se i když je termín přihlášek nastaven">
                      <template v-slot:activator="{ props }">
                        <v-switch v-bind="props" v-model="event.entry_is_open" label="Povolit přihlašování na soutěž" color="primary" hide-details></v-switch>
                      </template>
                    </v-tooltip>
                    <v-text-field v-model="race0.entry_deadline_1" type="datetime-local" label="1. termín přihlášek"></v-text-field>
                    <v-text-field v-model="race0.entry_deadline_2" type="datetime-local" label="2. termín přihlášek"></v-text-field>
                    <v-tooltip location="top center" origin="auto" text="Zapnutím povolíte závodníkům vybrat si dobu startu. Jejich rozhodnutí vidíte v exportu přihlášených">
                      <template v-slot:activator="{ props }">
                        <v-switch v-bind="props" v-model="event.allow_start_choice" color="primary" label="Umožnit závodníkům vybrat si dobu startu" hide-details></v-switch>
                      </template>
                    </v-tooltip>
                    <v-tooltip location="top center" origin="auto" text="Zapnutím povolíte přihlásit se na soutěž jen uživatelům přihlášeným v ROBisu">
                      <template v-slot:activator="{ props }">
                        <v-switch v-bind="props" v-model="event.auth_entry_only" label="Povolit přihlášku pouze registrovaným" color="primary" hide-details></v-switch>
                      </template>
                    </v-tooltip>

                    <a
                      class="custom-btn"
                      @click="dialogCategory = true"
                      title="změnami v této tabulce přepíšete kategorie ve všech etapách">
                      Spravovat kategorie hromadně
                    </a>

                    <!-- Dialogové okno pro správu kategorií -->
                    <v-dialog v-model="dialogCategory" max-width="1000px">
                      <v-card>
                        <v-card-title>
                          Správa kategorií
                          <v-btn color="green darken-1" @click="addNewCategory" style="float:right">Přidat kategorii</v-btn>
                        </v-card-title>
                        <v-card-text>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="bulkFee" label="Hromadné startovné" @change="applyBulkFee" suffix=",-Kč"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="bulkIncreasedFee" label="Hromadné zvýšené startovné" @change="applyBulkIncreasedFee" suffix=",-Kč"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="bulkFeeEvent" label="Hromadné startovné soutěže" @change="applyBulkFeeEvent" suffix=",-Kč"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-data-table
                            :items="categories"
                            :items-per-page="-1"
                            :headers="categoryHeaders"
                            item-key="id"
                            class="elevation-1"
                            :sort-by="[{ key: 'option'}]"
                            hide-default-footer>

                            <template v-slot:item="{ item }">

                              <tr>

                                <td>
                                  <v-select v-model="item.option" :items="CategoryType" dense flat solo style="min-width: 160px" :rules="optionRules"></v-select>
                                </td>
                                <td><v-text-field v-model="item.category_name" dense flat solo :rules="nameRules" maxlength="20" counter></v-text-field></td>
                                <!-- <td>
                                  <v-select
                                    v-model="item.sex"
                                    :items="[ { title: 'Žena', value: 'F' }, { title: 'Muž', value: 'M' }]"
                                    dense flat solo
                                    style="min-width: 100px;"
                                    :rules="sexRules"
                                    ></v-select>
                                </td> -->
                                <td><v-text-field v-model="item.fee" type="number" dense flat solo suffix=",-Kč"></v-text-field></td>
                                <td><v-text-field v-model="item.increased_fee" type="number" dense flat solo suffix=",-Kč"></v-text-field></td>
                                <td><v-text-field v-model="item.fee_event" type="number" dense flat solo suffix=",-Kč"></v-text-field></td>
                                <td style="min-width: 50px;"><v-icon color="red" small @click="markCategoryForDeletion(item.id)">mdi-delete</v-icon></td>

                              </tr>
                            </template>
                          </v-data-table>
                          <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="grey" @click="dialogCategory = false">Zavřít</v-btn>
                          <v-spacer></v-spacer>
                          <v-switch
                            v-model="change_allcategories"
                            color="primary"
                            label="Přepsat i v etapách"
                            hide-details
                          ></v-switch>
                          <v-btn color="green darken-1" @click="dialogCategoryRace0show" :disabled="!NotNameOption()">Uložit</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- Dialog pro potvrzení uložení a přepsání všech kategorií -->
                    <v-dialog v-model="dialogCategoryRace0" max-width="600px" class="dialog-over-dialog">
                      <v-card>
                        <v-card-title class="text-h5">Potvrzení uložení</v-card-title>
                        <v-card-text>Uložením přepíšete všechny kategorie ve všech etapách soutěže <b>{{ event.event_name }}</b>. Jednotlivé katgorie etap můžeš měnit v samostatných etapách. Opravdu chceš přepsat všechny kategorie?</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="dialogCategoryRace0 = false">Zrušit</v-btn>
                          <v-btn color="green darken-1" text @click="updateCategory">Aktualizovat a přepsat</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                  </v-col>

                <!-- Třetí sloupec -->
                <v-col cols="12" md="3">
                  <v-card>
                  <v-card-title>
                    Soubory:
                    <v-btn text color="surface" @click="showAddFileSection = !showAddFileSection" style="float: right;"><v-icon color="green" size="35" @click="addNewDay">mdi-plus</v-icon></v-btn>
                  </v-card-title>
                  <v-card-text>

                    <!-- Formulář pro přidání nového souboru -->
                    <div v-if="showAddFileSection">
                      <v-card>
                        <v-card-text>
                          <v-text-field v-model="newFile.name" label="Název souboru" placeholder="Např. Propozice" :rules="fileName"></v-text-field>
                          <v-select label="Typ souboru" v-model="newFile.specific" :items="fileTypes"></v-select>
                          <v-file-input label="Vybrat soubor" name="location" @change="handleNewFileChange" :error-messages="fileError"></v-file-input>
                          <v-btn color="success" @click="addNewFile">Nahrát</v-btn>
                        </v-card-text>
                      </v-card>
                    </div>

                      <v-list  v-for="file in filesWithLocation" :key="file.id">

                        <div v-if="!file.editing" class="d-flex justify-space-between">
                          <v-list-item-title>
                            <a :href="`${BaseUrl}${file.location}`" target="_blank" class="font-weight-bold">{{ file.name }}</a>
                          </v-list-item-title>

                        <v-list-item-action>
                          <!-- Ikona pro editaci -->
                          <v-icon color="blue" size="x-large" @click="editFile(file)" class="mr-2">mdi-pencil</v-icon>
                          <!-- Delete File Button -->
                          <v-icon color="red" size="x-large" @click="file.dialogDeleteFile = true">mdi-delete</v-icon>
                        </v-list-item-action>

                          <!-- Dialog pro potvrzení smazání Souboru -->
                          <v-dialog v-model="file.dialogDeleteFile" persistent max-width="600px">
                            <v-card>
                              <v-card-title class="text-h5">Potvrzení smazání souboru</v-card-title>
                              <v-card-text>Chcete opravdu smazat tento soubor <b>{{file.name}} - {{file.location}}</b>? Tato akce je nevratná!</v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="file.dialogDeleteFile = false">Zrušit</v-btn>
                                <v-btn color="red darken-1" text @click="deleteFile(file.id, file)">Smazat soubor</v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                        </div>

                        <div v-else style="background-color: #F4F6F6">
                          <!-- Editace souboru -->
                          <v-text-field v-model="file.name" label="Popis odkazu na soubor" placeholder="např. Propozice" :rules="fileName"></v-text-field>
                          <!-- Výběr kategorie -->
                          <v-select label="Typ soutěže (volitelně)" v-model="file.specific" :items="fileTypes" density="compact"></v-select>
                          <!-- File Upload Input -->
                          <v-file-input label="Vložit soubor" @change="handleNewFileChange($event, file)" outlined dense density="compact" :error-messages="fileError"></v-file-input>
                          <div>{{ file.location }}</div>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                          <!-- Uložení změn -->
                          <v-icon color="blue" @click="updateFiles(file)" size="34">mdi-content-save</v-icon>
                          </v-card-actions>
                        </div>
                      </v-list>
                  </v-card-text>
                  </v-card>

                  <v-card class="mt-2">
                    <v-card-title>
                      Odkazy:
                      <v-btn color="surface" @click="showAddLinkSection = !showAddLinkSection" style="float: right;" class="ml-3"><v-icon color="green" size="35" @click="addNewDay">mdi-plus</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                      <!-- Formulář pro přidání nového odkazu -->
                      <div v-if="showAddLinkSection">
                        <v-card>
                          <v-card-text>
                            <v-text-field v-model="newLink.name" label="Název odkazu" placeholder="Např. Počasí" :rules="linkName"></v-text-field>
                            <v-text-field v-model="newLink.url" label="URL odkazu" placeholder="https://"></v-text-field>

                            <v-btn color="success" @click="addNewLink">Přidat odkaz</v-btn>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-list v-for="file in filesWithLinks" :key="file.id">
                        <div v-if="!file.editing" class="d-flex justify-space-between">
                          <v-list-item-title><a :href="file.url" target="_blank" class="font-weight-bold">{{ file.name }}</a></v-list-item-title>
                          <v-list-item-action>
                            <v-icon color="blue" size="x-large" @click="editLink(file)" class="mr-2">mdi-pencil</v-icon>
                            <v-icon color="red" size="x-large" @click="file.dialogDeleteFile = true" >mdi-delete</v-icon>
                          </v-list-item-action>
                          <!-- Dialog pro potvrzení smazání Odkazu -->
                          <v-dialog v-model="file.dialogDeleteFile" persistent max-width="600px">
                              <v-card>
                                <v-card-title class="text-h5">Potvrzení smazání souboru</v-card-title>
                                <v-card-text>Chcete opravdu smazat tento odkaz <b>{{file.name}} - {{file.url}}</b>? Tato akce je nevratná!</v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="file.dialogDeleteFile = false">Zrušit</v-btn>
                                  <v-btn color="red darken-1" text @click="deleteFile(file.id, file)">Smazat odkaz</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                        </div>
                        <div v-else style="background-color: #F4F6F6">
                            <!-- Editace souboru -->
                            <v-text-field v-model="file.name" label="Název odkazu" placeholder="např. Počasí" :rules="linkName"></v-text-field>
                            <v-text-field v-model="file.url" label="URL odkazu" placeholder="https://"></v-text-field>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                            <!-- Uložení změn -->
                            <v-icon color="blue" @click="updateFiles(file)" size="34">mdi-content-save</v-icon>
                            </v-card-actions>
                          </div>

                      </v-list>
                    </v-card-text>
                  </v-card>
                <!-- Konec třetího sloupce -->
                </v-col>

              </v-row>

              <v-row class="d-flex justify-end">
                <v-col cols="12" md="auto" class="d-flex flex-column flex-md-row align-items-md-end">
                  <v-btn color="success" type="submit" class="mb-2 w-100 w-md-auto order-md-1">Uložit změny</v-btn>
                  <v-btn color="error" dark @click="dialogDeleteEvent = true" class="w-100 w-md-auto mr-5">Smazat soutěž</v-btn>
                </v-col>
              </v-row>

            </v-form>

            </v-card>
          </v-window-item>

<!-- ***************************************
 *******************************************
 ********          ETAPY          **********
 *******************************************
 ****************************************-->

          <!-- Dynamické generování obsahu pro etapy -->
          <v-window-item v-for="(race, etap) in races" :key="race.id" :value="`race-${race.id}`">
          <v-form ref="form" @submit.prevent="saveEvent">
          <v-row>
            <v-col cols="12" md="6">
              <v-card-title>Etapa {{ etap + 1 }}: {{ race.race_name }}</v-card-title>
                <v-col>ID: {{ race.id }}</v-col>
                <v-text-field v-model="race.race_name" label="Název etapy" :rules="eventTitle" maxlength="40" counter></v-text-field>
                <v-text-field v-model="race.race_date" label="Datum etapy" type="date" required :rules="eventDate"></v-text-field>
                <v-text-field v-model="race.race_startTime" type="time" label="Start 00" :value="race.race_start ? $formatTime(race.race_start) : ''" @input="updaterace_startTime(race)" clearable></v-text-field>

                <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-title color="primary">Další nastavení etapy</v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <v-select :items="raceLevel" v-model="race.race_level" label="Úroveň závodu" :rules="eventType"></v-select>
                          <v-select v-model="race.race_band" :items="race_bands" clearable label="Pásmo"></v-select>
                          <v-select v-model="race.race_type" :items="raceType" clearable label="Typ závodu"></v-select>
                          <v-text-field v-model="race.race_time_limit" type="time" label="Limit v hh:mm"></v-text-field>
                          <v-text-field v-model="race.message_info" label="Zpráva - informace" maxlength="500" counter></v-text-field>
                          <v-text-field v-model="race.message_warning" label="Zpráva - varování" maxlength="500" counter></v-text-field>
                          <v-textarea v-model="race.race_note" label="Poznámka" maxlength="1000" counter></v-textarea>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>

              <!-- Dialog pro potvrzení smazání Etapy -->
              <v-dialog v-model="race.dialogDeleteRace" persistent max-width="600px">
                <v-card>
                  <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
                  <v-card-text>Chcete opravdu smazat tuto Etapu <b>{{race.race_name}}</b>? Tato akce je nevratná a smaže i všechna data v etapě!!!</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="race.dialogDeleteRace = false">Zrušit</v-btn>
                    <v-btn color="red darken-1" text @click="deleteRace(event.id, race.id, race), race.dialogDeleteRace = false">Smazat</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>


            </v-col>


                <!-- Druhý sloupec -->
                <v-col cols="12" md="3" class="mt-3">
                    <!-- <v-card-title>Termín přihlášky pro Etapu</v-card-title>
                    <v-text-field v-model="race.entry_deadline_1" type="datetime-local" label="1. termín přihlášek pro etapu"></v-text-field>
                    <v-text-field v-model="race.entry_deadline_2" type="datetime-local" label="2. termín přihlášek pro etapu"></v-text-field> -->


                    <div cols="12" md="12" class="text-center">
                    <v-btn color="primary" @click="dialogCategoryRace = true" class="w-100">Spravovat kategorie</v-btn>
                    </div>
                    <!-- Dialogové okno pro správu kategorií -->
                    <v-dialog v-model="dialogCategoryRace" max-width="800px">
                      <v-card>
                        <v-card-title>
                          Správa kategorií
                          <v-btn color="green darken-1" @click="addNewCategory" style="float: right;">Přidat kategorii</v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                          <v-col cols="6">
                            <v-text-field v-model="bulkFee" label="Hromadné startovné" @change="applyBulkFee" suffix=",-Kč"></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field v-model="bulkIncreasedFee" label="Hromadné zvýšené startovné" @change="applyBulkIncreasedFee" suffix=",-Kč"></v-text-field>
                          </v-col>
                        </v-row>
                          <v-data-table
                            :items="categories"
                            :items-per-page="-1"
                            :headers="categoryHeadersEvents"
                            item-key="id"
                            class="elevation-1"
                            :sort-by="[{ key: 'option'}]"
                            hide-default-footer>

                            <template v-slot:item="{ item }">

                              <tr>

                                <td>
                                  <v-select v-model="item.option" :items="CategoryType" dense flat solo style="min-width: 160px;" :rules="optionRules"></v-select>
                                </td>
                                <td><v-text-field v-model="item.category_name" dense flat solo :rules="nameRules" maxlength="20" counter></v-text-field></td>

                                <td><v-text-field v-model="item.fee" type="number" dense flat solo suffix=",-Kč"></v-text-field></td>
                                <td><v-text-field v-model="item.increased_fee" type="number" dense flat solo suffix=",-Kč"></v-text-field></td>
                                <td style="min-width: 50px;"><v-icon color="red" small @click="markCategoryForDeletion(item.id)">mdi-delete</v-icon></td>

                              </tr>

                            </template>
                          </v-data-table>
                          <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="grey" @click="dialogCategoryRace = false">Zavřít</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn color="green darken-1" @click="updateCategory">Uložit</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>


                    <v-col cols="12">
                      <v-text-field
                        label="API klíč"
                        variant="outlined"
                        density="compact"
                        v-model="race.race_api_key"
                        append-inner-icon="mdi-content-copy"
                        @click:append-inner="copyKey(race)"
                        class="mt-4"
                        hide-details
                        readonly
                      ></v-text-field>
                      <v-chip v-if="copied" class="my-2" append-icon="mdi-content-copy" color="green" variant="elevated" size="small">Zkopírováno</v-chip>
                    </v-col>
                      


                    

                </v-col>

                <!-- Třetí sloupec -->
                <v-col cols="12" md="3">
                  <v-card>
                  <v-card-title>
                    Soubory:
                    <v-btn color="surface" @click="showAddFileSection = !showAddFileSection" style="float: right;"><v-icon color="green" size="35" @click="addNewDay">mdi-plus</v-icon></v-btn>
                  </v-card-title>
                  <v-card-text>

                    <!-- Formulář pro přidání nového souboru -->
                    <div v-if="showAddFileSection">
                      <v-card>
                        <v-card-text>
                          <v-text-field v-model="newFile.name" label="Název souboru" placeholder="Např. Propozice" :rules="fileName"></v-text-field>
                          <v-select label="Typ souboru" v-model="newFile.specific" :items="fileTypes"></v-select>
                          <v-file-input label="Vybrat soubor" name="location" @change="handleNewFileChange" :error-messages="fileError"></v-file-input>
                          <v-btn color="success" @click="addNewFile">Nahrát</v-btn>
                        </v-card-text>
                      </v-card>
                    </div>

                      <v-list  v-for="file in filesWithLocation" :key="file.id">

                        <div v-if="!file.editing" class="d-flex justify-space-between">
                          <v-list-item-title>
                            <a :href="`${BaseUrl}${file.location}`" target="_blank" class="font-weight-bold">{{ file.name }}</a>
                          </v-list-item-title>

                        <v-list-item-action>
                          <!-- Ikona pro editaci -->
                          <v-icon color="blue" size="x-large" @click="editFile(file)" class="mr-2">mdi-pencil</v-icon>
                          <!-- Delete File Button -->
                          <v-icon color="red" size="x-large" @click="file.dialogDeleteFile = true">mdi-delete</v-icon>
                        </v-list-item-action>

                          <!-- Dialog pro potvrzení smazání Souboru -->
                          <v-dialog v-model="file.dialogDeleteFile" persistent max-width="600px">
                            <v-card>
                              <v-card-title class="text-h5">Potvrzení smazání souboru</v-card-title>
                              <v-card-text>Chcete opravdu smazat tento soubor <b>{{file.name}} - {{file.location}}</b>? Tato akce je nevratná!</v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="file.dialogDeleteFile = false">Zrušit</v-btn>
                                <v-btn color="red darken-1" text @click="deleteFile(file.id, file)">Smazat soubor</v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                        </div>

                        <div v-else style="background-color: #F4F6F6">
                          <!-- Editace souboru -->
                          <v-text-field v-model="file.name" label="Popis odkazu na soubor" placeholder="např. Propozice" :rules="fileName"></v-text-field>
                          <!-- Výběr kategorie -->
                          <v-select label="Typ soutěže (volitelně)" v-model="file.specific" :items="fileTypes" density="compact"></v-select>
                          <!-- File Upload Input -->
                          <v-file-input label="Vložit soubor" @change="handleNewFileChange($event, file)" outlined dense density="compact" :error-messages="fileError"></v-file-input>
                          <div style="margin-top: -18px;">{{ file.location }}</div>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                          <!-- Uložení změn -->
                          <v-icon color="blue" @click="updateFiles(file)" size="34">mdi-content-save</v-icon>
                          </v-card-actions>
                        </div>
                      </v-list>
                  </v-card-text>
                  </v-card>

                  <v-card class="mt-2">
                    <v-card-title>
                      Odkazy:
                      <v-btn color="surface" @click="showAddLinkSection = !showAddLinkSection" style="float: right;" class="ml-3"><v-icon color="green" size="35" @click="addNewDay">mdi-plus</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                      <!-- Formulář pro přidání nového odkazu -->
                      <div v-if="showAddLinkSection">
                        <v-card>
                          <v-card-text>
                            <v-text-field v-model="newLink.name" label="Název odkazu" placeholder="Např. Počasí" :rules="linkName"></v-text-field>
                            <v-text-field v-model="newLink.url" label="URL odkazu" placeholder="https://"></v-text-field>

                            <v-btn color="success" @click="addNewLink">Přidat odkaz</v-btn>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-list v-for="file in filesWithLinks" :key="file.id">
                        <div v-if="!file.editing" class="d-flex justify-space-between">
                          <v-list-item-title><a :href="file.url" target="_blank" class="font-weight-bold">{{ file.name }}</a></v-list-item-title>
                          <v-list-item-action>
                            <v-icon color="blue" size="x-large" @click="editLink(file)" class="mr-2">mdi-pencil</v-icon>
                            <v-icon color="red" size="x-large" @click="file.dialogDeleteFile = true" >mdi-delete</v-icon>
                          </v-list-item-action>
                          <!-- Dialog pro potvrzení smazání Odkazu -->
                          <v-dialog v-model="file.dialogDeleteFile" persistent max-width="600px">
                              <v-card>
                                <v-card-title class="text-h5">Potvrzení smazání souboru</v-card-title>
                                <v-card-text>Chcete opravdu smazat tento odkaz <b>{{file.name}} - {{file.url}}</b>? Tato akce je nevratná!</v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="file.dialogDeleteFile = false">Zrušit</v-btn>
                                  <v-btn color="red darken-1" text @click="deleteFile(file.id, file)">Smazat odkaz</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                        </div>
                        <div v-else style="background-color: #F4F6F6">
                            <!-- Editace souboru -->
                            <v-text-field v-model="file.name" label="Název odkazu" placeholder="např. Počasí" :rules="linkName"></v-text-field>
                            <v-text-field v-model="file.url" label="URL odkazu" placeholder="https://"></v-text-field>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                            <!-- Uložení změn -->
                            <v-icon color="blue" @click="updateFiles(file)" size="34">mdi-content-save</v-icon>
                            </v-card-actions>
                          </div>

                      </v-list>
                    </v-card-text>
                  </v-card>
            <!-- Konec třetího sloupce -->
                </v-col>
              </v-row>

              <v-row class="d-flex justify-end">
                <v-col cols="12" md="auto" class="d-flex flex-column flex-md-row align-items-md-end">
                  <v-btn color="success" type="submit" class="mb-2 w-100 w-md-auto order-md-1">Uložit změny</v-btn>
                  <v-btn color="error" dark @click="race.dialogDeleteRace = true" class="w-100 w-md-auto mr-5">Smazat Etapu</v-btn>
                </v-col>
              </v-row>

              </v-form>

          </v-window-item>

        </v-window>
      </v-card-text>

      <!-- Další záložky, které jsou pro celý event -->

      <v-tabs v-model="second" bg-color="primary" fixed-tabs slider-color="#F1A829">
        <v-tab value="entries" v-if="isRace0">Správa přihlášených</v-tab>
        <v-tab value="acc_food" v-if="isRace0">Ubytování a strava</v-tab>
        <v-tab value="services" v-if="isRace0">Služby</v-tab>
        <v-tab value="startlist" v-if="!isRace0">Startovka</v-tab>
        <v-tab value="results" v-if="!isRace0">Výsledky</v-tab>


      </v-tabs>
      <v-card-text>


        <!-- ***************************************
 *******************************************
 ********  Správa přihlášených    **********
 *******************************************
 ****************************************-->



        <v-window v-model="second">

          <v-window-item value="entries" v-if="isRace0">


            <v-row class="align-center justify-space-between">

              <v-col cols="12" md="2" v-if="isRace0 && entries.length > 0 || searchSurname">
                <v-menu offset-y transition="slide-y-transition">
                    <template v-slot:activator="{ props }">
                    <v-btn color="green" text v-bind="props">
                        Exporty <v-icon right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item :href="`${BaseUrl}/export/?type=xlsx&event_id=${event.id}`"><v-list-item-title>Soutěž xlsx</v-list-item-title></v-list-item>
                      <v-list-item :href="`${BaseUrl}/export/?type=csv&name=entry_matula&event_id=${event.id}`"><v-list-item-title>Přihlášky Matula csv</v-list-item-title></v-list-item>
                      <v-list-item :href="`${BaseUrl}/export/?type=csv&name=entry_ocm&event_id=${event.id}`"><v-list-item-title>Přihlášky OCM csv</v-list-item-title></v-list-item>
                    </v-list>
                  </v-menu>

              </v-col>
              <v-col cols="12" md="3" v-if="isRace0 && entries.length > 0">
                <v-tooltip location="top center" origin="auto" text="Zkopíruje emaily všech, kteří jsou nyní vidět v editaci">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" color="primary" @click="saveEmailsToClipboard">Zkopírovat emaily</v-btn>
                  </template>
                </v-tooltip>
                <v-snackbar v-model="showSnackbar" top color="success">
                  Emaily byly zkopírovány.
                </v-snackbar>
              </v-col>

              <!--   Příhlásit nového závodníka    -->



              <v-col cols="12" md="2">
                <v-btn color="green" @click="dialogCreateEntryForm = true">Přihlásit</v-btn>
              </v-col>

              <v-dialog v-model="dialogCreateEntryForm" max-width="1200px" :persistent="false">
                <v-card>
                  <v-row>
                    <v-card-title class="ma-4">Vytvořit přihlášku</v-card-title>
                      <!-- Přepínač pro výběr mezi zobrazeními -->
                    <v-col cols="12" md="8">
                      <v-switch
                        v-model="swichCreateEntryForm"
                        :label="swichCreateEntryForm ? 'Přihlásit z databáze' : 'Přihlásit neregistrovaného'"
                        hide-details
                        color="primary"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-card-text>
                    <v-form ref="entryForm">
                      <div v-if="registeredUsersForm">
                        <v-row>
                          <!-- Vyhledání v databázi a vyplění polí -->
                          <v-col cols="12" md="4">
                            <v-autocomplete
                            v-model="selectedEntryId"
                            :items="filteredEntries"
                            :item-title="getFullName"
                            item-value="id"
                            label="Vyhledat podle příjmení"
                            :loading="isLoading"
                            no-data-text="Příjmení není v databázi"
                            clearable
                            @update:search="onlast_nameChange"
                            @change="onEntrySelected"
                            class="ma-1"
                            hide-details
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12" md="3">
                            <!-- Kategorie pro hlavní soutěž -->
                            <v-select
                              v-model="newEntry.main_category_id"
                              :items="mainCategories"
                              item-title="category_name"
                              item-value="id"
                              label="Kategorie pro hlavní soutěž"
                              :error-messages="errors.main_category_id"
                            ></v-select>
                          </v-col>
                          
                          <!-- Poznámka -->
                          <v-col cols="12" md="3">
                            <v-text-field
                              label="Poznámka"
                              v-model="newEntry.note"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="2" v-if="si_rent.length > 0 && si_rent[0].price !== null">
                            <v-checkbox
                            v-model="selectedServices.si_rent"
                            :label="`Půjčit čip`"
                            hide-details
                            density="compact"
                            />
                          </v-col>
                    
                        </v-row>
                      </div>

                      <div v-else>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field label="Příjmení" v-model="newEntry.last_name" :error-messages="errors.last_name"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field label="Jméno" v-model="newEntry.first_name" :error-messages="errors.first_name"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field label="Číslo SI" v-model="newEntry.si_number" type="number" hide-details></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field label="Rok narození" v-model="newEntry.birth_year" type="number" hide-details></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-select label="Pohlaví" v-model="newEntry.competitor_gender" :items="gender" hide-details></v-select>
                          </v-col>
                          <v-col cols="12" md="4">
                            <!-- Kategorie pro hlavní soutěž -->
                            <v-select
                              v-model="newEntry.main_category_id"
                              :items="mainCategories"
                              item-title="category_name"
                              item-value="id"
                              label="Kategorie pro hlavní soutěž"
                              :error-messages="errors.main_category_id"
                            ></v-select>
                          </v-col>
                        </v-row>

                        <v-row>
                        <!--
                          <v-col cols="12" md="3">
                            <v-select
                            label="Termín přihlášky"
                            v-model="newEntry.termin_prihl"
                            :items="terminPrihlOptions"
                            item-title="text"
                            item-value="value"
                            ></v-select>
                          </v-col>
                        -->

                          <v-col cols="12" md="4">
                            <v-text-field label="Email" v-model="newEntry.email" :error-messages="errors.email"></v-text-field>
                          </v-col>

                          <!-- Poznámka -->
                          <v-col cols="12" md="5">
                            <v-text-field
                              label="Poznámka"
                              v-model="newEntry.note"
                              hide-details
                            ></v-text-field>
                          </v-col>
                  
                          <v-col cols="12" md="3" v-if="si_rent.length > 0 && si_rent[0].price !== null">
                            <v-checkbox
                            
                            v-model="selectedServices.si_rent"
                            :label="`Půjčit čip`"
                            hide-details
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <!-- Kategorie pro jednotlivé etapy (races) -->
                      <v-row justify="center">
                        <v-col v-for="(race) in races" :key="race.id" cols="12" md="3" style="background-color:#EDF5FF">
                          <v-card class="pa-2">
                            <v-card-title>{{ race.race_name }}</v-card-title>
                            <v-select
                              v-model="newEntry.race_categories[race.id]"
                              :items="race.categories"
                              item-title="category_name"
                              item-value="id"
                              :label="`Kategorie pro etapu: ${race.race_name}`"
                              clearable
                            ></v-select>
                            <v-select v-if="event.allow_start_choice" density="comfortable" :items="startGroups" item-title="text" item-value="value" v-model="newEntry.start_preference[race.id]" label="Startovní skupina"></v-select>
                          </v-card>
                        </v-col>
                      </v-row>


                          <!-- Sekce pro výběr ubytování podle dnů -->
                      <h3 v-if="accommodationVariants.length > 0" class="py-2 my-1 mt-5" style="background-color: #e8efff">Ubytování:</h3>

                      <div v-for="variant in accommodationVariants" :key="variant.variant">
                        <span :style="{ color: variant.limit && variant.count >= variant.limit ? 'red' : 'inherit' }">
                          Typ {{ variant.variant }}: {{ variant.count }} přihlášených
                          <span v-if="variant.limit">/ limit: {{ variant.limit }}</span>
                        </span>
                      </div>


                      <!-- Zobrazení ubytování podle dat -->
                      <v-row>
                        <v-col v-for="(accommodations, date) in availableAccommodations" :key="date" cols="12" md="3">
                          <h4>{{ $formatDate(date) }}</h4>
                          <v-select
                            v-if="accommodations.length > 1"
                            v-model="selectedServices.accommodations[date]"
                            :items="accommodations"
                            item-title="name"
                            item-value="id"
                            :label="`Vyber ubytování`"
                            clearable
                            hide-details
                          ></v-select>
                          <div v-else v-for="accommodation in accommodations" :key="accommodation.id">
                            <v-checkbox
                              v-model="selectedServices.accommodations[date]"
                              :value="accommodation.id"
                              :label="`${accommodation.name} - ${accommodation.price} Kč`"
                              hide-details
                            ></v-checkbox>
                          </div>
                        </v-col>
                      </v-row>

                      <!-- Zobrazení stravy -->
                      <h3 v-if="Object.keys(availableFood).length > 0" class="py-2 my-1" style="background-color: #e8efff">Strava</h3>
                      <v-row>
                      <v-col v-for="(meals, date) in availableFood" :key="date">
                        <h4>{{ $formatDate(date) }}</h4>

                        <!-- Snídaně -->
                        <v-subheader v-if="meals.breakfasts && meals.breakfasts.length">Snídaně</v-subheader>
                        <div v-if="meals.breakfasts && meals.breakfasts.length">
                          <v-checkbox
                            v-for="meal in meals.breakfasts"
                            :key="meal.id"
                            v-model="selectedServices.breakfasts[date]"
                            :value="meal.id"
                            :label="`${meal.name} - ${meal.price} Kč`"
                            @change="onFoodChange(date, 'breakfast', selectedServices.breakfasts[date])"
                            hide-details
                          />
                        </div>

                        <!-- Obědy -->
                        <v-subheader v-if="meals.lunches && meals.lunches.length">Oběd</v-subheader>
                        <div v-if="meals.lunches && meals.lunches.length > 1">
                          <v-select
                            v-model="selectedServices.lunches[date]"
                            :items="meals.lunches"
                            item-title="name"
                            item-value="id"
                            :label="`Vyber oběd`"
                            @change="onFoodChange(date, 'lunch', selectedServices.lunches[date])"
                            clearable
                            hide-details
                          ></v-select>
                        </div>
                        <div v-else-if="meals.lunches && meals.lunches.length === 1">
                          <v-checkbox
                            v-for="meal in meals.lunches"
                            :key="meal.id"
                            v-model="selectedServices.lunches[date]"
                            :value="meal.id"
                            :label="`${meal.name} - ${meal.price} Kč`"
                            @change="onFoodChange(date, 'lunch', selectedServices.lunches[date])"
                            hide-details
                          />
                        </div>

                        <!-- Večeře -->
                        <v-subheader v-if="meals.dinners && meals.dinners.length">Večeře</v-subheader>
                        <div v-if="meals.dinners && meals.dinners.length > 1">
                          <v-select
                            v-model="selectedServices.dinners[date]"
                            :items="meals.dinners"
                            item-title="name"
                            item-value="id"
                            :label="`Vyber večeři`"
                            clearable
                            @change="onFoodChange(date, 'dinner', selectedServices.dinners[date])"
                            hide-details
                          ></v-select>
                        </div>
                        <div v-else-if="meals.dinners && meals.dinners.length === 1">
                          <v-checkbox
                            v-for="meal in meals.dinners"
                            :key="meal.id"
                            v-model="selectedServices.dinners[date]"
                            :value="meal.id"
                            :label="`${meal.name} - ${meal.price} Kč`"
                            @change="onFoodChange(date, 'dinner', selectedServices.dinners[date])"
                            hide-details
                          />
                        </div>
                      </v-col>
                    </v-row>
                    </v-form>
                    <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="grey" text @click="dialogCreateEntryForm = false">Zrušit</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="submitEntry">Přihlásit</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!--   Konec příhlásit nového závodníka    -->

              <v-col cols="12" md="3" class="text-right" v-if="isRace0 && entries.length > 0 || searchSurname">
                <v-text-field
                  v-model="searchSurname"
                  label="Vyhledat podle příjmení"
                  @input="fetchEntries"
                  @click:clear="resetSearch"
                  solo
                  clearable
                  hide-details
                  style="max-width: 300px; margin-left: auto;"
                ></v-text-field>
              </v-col>
            </v-row>


              <v-data-table
                :headers="entryHeaders"
                :items="entries"
                item-key="id"
                class="elevation-1"
                density="comfortable"
                :items-per-page="100"
                :sort-by="sortByEntries"
                @touchstart.stop
                @touchmove.stop
                :loading="isLoadingEntries"
                loading-text="Načítám data..."
                v-if="isRace0 && entries.length > 0 || searchSurname"
              >
                <template v-slot:[`item.last_name`]="{ item }">
                  <div class="d-flex align-center">
                  {{ item.last_name }}
                  <v-tooltip location="top center" origin="auto" text="Není přihlášený/á pomocí svého účtu. Údaje nemusejí být pravdivé">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" v-if="!item.auth_user" color="red darken-1" class="ml-1">mdi-alert-circle</v-icon>
                    </template>
                  </v-tooltip>
                  </div>
                </template>
                <template v-slot:[`item.first_name`]="{ item }">
                  {{ item.first_name }}
                </template>
                <template v-slot:[`item.competitor_index`]="{ item }">
                  {{ item.competitor_index }}
                </template>
                <template v-slot:[`item.si_number`]="{ item }">
                  {{ item.si_number }}
                </template>
                <template v-slot:[`item.category_name`]="{ item }">
                  {{ item.category_name }}
                </template>
                <template v-slot:[`item.termin_prihl`]="{ item }">
                  {{ item.termin_prihl }}
                </template>
                <template v-slot:[`item.note`]="{ item }">
                  {{ item.note }}
                </template>
                <template v-slot:[`item.timestamp`]="{ item }">
                  {{ $formatDateTime(item.timestamp) }}
                </template>


                <template v-slot:[`item.actions`]="{ item }">

                    <v-list-item-action>
                    <v-icon @click="openEditDialog(item)" color="blue" size="x-large" class="mr-2">mdi-pencil</v-icon>
                    <v-icon @click="openDeleteDialog(item)" color="red" size="x-large">mdi-delete</v-icon>
                  </v-list-item-action>
                </template>
              </v-data-table>



            <!-- Dialog pro editaci Entry -->
            <v-dialog v-model="dialogEditEntry" persistent max-width="1200px">
              <v-card>
                <v-card-text>
                  <h3 class="my-2">
                    {{ selectedEntry?.last_name }} {{ selectedEntry?.first_name }} ({{ selectedEntry?.competitor_index }})
                  </h3>

                  <!-- Checkbox pro půjčení čipu (si_rent) -->
                  <v-col cols="12" md="6" v-if="si_rent.length > 0 && si_rent[0].price !== null">
                    <v-checkbox
                      v-model="siRentChecked"
                      label="Půjčit čip"
                      hide-details
                      density="compact"
                    ></v-checkbox>
                  </v-col>
    
                  <div class="mb-3" v-for="(race, index) in allRaces" :key="race.id" :class="{ 'first-row': index === 0 }">
                     <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="getEntryRaceData(selectedEntry, race.id).category_id"
                          :items="race.categories"
                          item-title="category_name"
                          item-value="id"
                          :label="index === 0 ? `Kategorie pro hlavní soutěž ${race.race_name}` : `Kategorie pro etapu ${race.race_name}`"
                          :clearable="index !== 0"
                          hide-details="true"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="getEntryRaceData(selectedEntry, race.id).si_number"
                          :label="index === 0 ? `Číslo SI pro hlavní soutěž ${race.race_name}` : `Číslo SI pro etapu ${race.race_name}`"
                          type="number"
                          hide-details="true"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- Sekce pro výběr ubytování podle dnů -->
                  <h3 v-if="accommodationVariants.length > 0" class="py-2 my-1" style="background-color: #e8efff">Ubytování:</h3>

                  <div v-for="variant in accommodationVariants" :key="variant.variant">
                    <span :style="{ color: variant.limit && variant.count >= variant.limit ? 'red' : 'inherit' }">
                      Typ {{ variant.variant }}: {{ variant.count }} přihlášených
                      <span v-if="variant.limit">/ limit: {{ variant.limit }}</span>
                    </span>
                  </div>




                  <v-row>
                    <!-- Pro každé datum zobrazíme vlastní sloupec -->
                    <v-col
                      v-for="(date, index) in Object.keys(availableAccommodations)"
                      :key="index"
                      cols="12"
                      md="3"
                    >
                      <h4>{{ $formatDate(date) }}</h4>
                      <div v-if="availableAccommodations[date].length === 1">
                        <v-checkbox
                          v-model="selectedEntry.accommodation[date]"
                          :label="availableAccommodations[date][0].name"
                          :value="availableAccommodations[date][0].id"
                        ></v-checkbox>
                      </div>
                      <div v-else>
                        <v-select
                          v-model="selectedEntry.accommodation[date]"
                          :items="availableAccommodations[date]"
                          item-title="name"
                          item-value="id"
                          label="Vyberte ubytování"
                          clearable
                        ></v-select>
                      </div>
                    </v-col>
                  </v-row>



                  <!-- Sekce pro výběr stravy podle dnů -->
                  <h3 v-if="Object.keys(availableFood).length > 0" class="py-2 my-1" style="background-color: #e8efff">Strava:</h3>
                  <v-row>
                    <v-col
                      v-for="(date, index) in Object.keys(availableFood)"
                      :key="index"
                      cols="12"
                      md="3"
                    >
                      <h4>{{ $formatDate(date) }}</h4>

                      <!-- Snídaně -->
                      <v-subheader v-if="availableFood[date].breakfasts.length">Snídaně</v-subheader>
                      <v-checkbox
                        v-for="(item, idx) in availableFood[date].breakfasts"
                        :key="`breakfast-${idx}`"
                        v-model="selectedEntry.food[date].breakfasts"
                        :label="item.name"
                        :value="item.id"
                        hide-details
                      ></v-checkbox>

                      <!-- Obědy -->
                      <template v-if="availableFood[date].lunches.length">
                        <v-subheader>Oběd</v-subheader>
                        <div v-if="availableFood[date].lunches.length === 1">
                          <v-checkbox
                            v-for="(item, idx) in availableFood[date].lunches"
                            :key="`lunch-${idx}`"
                            v-model="selectedEntry.food[date].lunches"
                            :label="item.name"
                            :value="item.id"
                            hide-details
                          ></v-checkbox>
                        </div>
                        <div v-else>
                          <v-select
                            v-model="selectedEntry.food[date].lunches"
                            :items="availableFood[date].lunches"
                            item-title="name"
                            item-value="id"
                            label="Vyberte oběd"
                            clearable
                            hide-details
                          ></v-select>
                        </div>
                      </template>

                      <!-- Večeře -->
                      <template v-if="availableFood[date].dinners.length">
                        <v-subheader>Večeře</v-subheader>
                        <div v-if="availableFood[date].dinners.length === 1">
                          <v-checkbox
                            v-for="(item, idx) in availableFood[date].dinners"
                            :key="`dinner-${idx}`"
                            v-model="selectedEntry.food[date].dinners"
                            :label="item.name"
                            :value="item.id"
                            hide-details
                          ></v-checkbox>
                        </div>
                        <div v-else>
                          <v-select
                            v-model="selectedEntry.food[date].dinners"
                            :items="availableFood[date].dinners"
                            item-title="name"
                            item-value="id"
                            label="Vyberte večeři"
                            clearable
                            hide-details
                          ></v-select>
                        </div>
                      </template>
                    </v-col>
                  </v-row>

                  <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="dialogEditEntry = false">Zrušit</v-btn>
                  <v-btn color="green darken-1" text @click="editEntry">Uložit</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>



            <!-- Dialog pro potvrzení smazání Entry -->
            <v-dialog v-model="dialogDeleteEntry" persistent max-width="600px">
              <v-card>
                <v-card-title class="text-h5">Potvrzení smazání přihlášky</v-card-title>
                <v-card-text>Chcete opravdu smazat přihlášku pro <b>{{ selectedEntry?.last_name }} {{ selectedEntry?.first_name }}</b>? Tato akce je nevratná a smaže přihlášky závodníka ve všech etapách!!!</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogDeleteEntry = false">Zrušit</v-btn>
                  <v-btn color="red darken-1" text @click="deleteEntry">Smazat</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


          </v-window-item>


<!-- ***************************************
 *******************************************
 ********    UBYTOVÁÍ + STRAVA    **********
 *******************************************
 ****************************************-->


          <v-window-item value="acc_food" v-if="isRace0">

            <v-form ref="entryForm">
              <v-row>
                <v-col v-for="(acc, index) in accommodations" :key="index" cols="12" md="3">
                  <v-col>
                    <v-row justify="space-between">
                      <v-col cols="auto" class="mb-3">
                        <span>Ubytování - varianta {{ acc.variant }}</span>
                      </v-col>
                      <v-col cols="auto">
                        <v-icon v-if="acc.name" color="red" title="resetovat formulář a vymazat jeho data" size="x-large" @click="showAccomodationDialog(index)">mdi-close</v-icon>
                      </v-col>
                    </v-row>
                    <v-text-field v-model="acc.name" label="Název ubytování" maxlength="30" counter :error-messages="fieldErrors[`acc${index}`]" variant="outlined" density="compact"></v-text-field>
                    <v-text-field v-model="acc.price" label="Cena" type="number" :error-messages="fieldErrors[`acc${index}`]" variant="outlined" density="compact"></v-text-field>
                    <v-text-field v-model="acc.limit" label="Kapacita" type="number" variant="outlined" density="compact"></v-text-field>
                    <v-text-field v-model="acc.date_from" :disabled="acc.loadedFromAPI" label="Datum od" type="date" :error-messages="fieldErrors[`acc${index}`]" variant="outlined" density="compact"></v-text-field>
                    <v-text-field v-model="acc.date_to" :disabled="acc.loadedFromAPI" label="Datum do" type="date" :error-messages="fieldErrors[`acc${index}`]" variant="outlined" density="compact"></v-text-field>
                    <!-- <v-switch v-model="acc.only_all_days" color="primary" label="Přihlášení pouze na všechny dny" density="compact"</v-switch> -->
                  </v-col>

                  <!-- Dialog pro potvrzení smazání ubytování -->
                  <v-dialog v-model="dialogAccomodations[index]" persistent max-width="600px">
                    <v-card>
                      <v-card-title class="text-h5">Potvrzení smazání ubytování</v-card-title>
                      <v-card-text>Chceš opravdu smazat tento typ ubytování s názvem <b>{{ acc.name }}</b>? Tato akce je nevratná!</v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogAccomodations[index] = false">Zrušit</v-btn>
                        <v-btn color="red darken-1" text @click="resetAccommodation(index); dialogAccomodations[index] = false;">Smazat variantu</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                </v-col>
              </v-row>

              <!-- Jídla -->
              <v-col cols="auto">
                <v-btn color="primary" @click="addNewDay"><v-icon color="green" size="35" @click="addNewDay">mdi-plus</v-icon> Přidej další dny jídla</v-btn>
              </v-col>
              <v-row>
                <v-col cols="12" md="4" v-for="(day, index) in days" :key="index" :class="{'background-color-1': index % 2 === 0, 'background-color-2': index % 2 !== 0}">
                  <v-text-field v-model="day.date" :error-messages="fieldErrors['date' + day.date]" label="Datum" clearable type="date"></v-text-field>
                  <v-row>
                    <!-- Header for Breakfast -->
                    <v-col cols="12" md="4">
                      <div class="text-center mb-3">Snídaně</div>
                      <div v-for="meal in day.breakfasts" :key="meal.variant">
                        <v-text-field v-model="meal.name" label="Název" :error-messages="fieldErrors['sn' + meal.variant]" clearable maxlength="30" counter variant="outlined" density="compact"></v-text-field>
                        <v-text-field v-model="meal.price" label="Cena" type="number" :error-messages="fieldErrors['sn' + meal.variant]" clearable variant="outlined" density="compact"></v-text-field>
                      </div>
                    </v-col>
                    <!-- Header for Lunch -->
                    <v-col cols="12" md="4">
                      <div class="text-center mb-3">Oběd</div>
                        <div v-for="meal in day.lunches" :key="meal.variant">
                        <v-text-field v-model="meal.name" :label="`Název (varianta ${meal.variant})`" :error-messages="fieldErrors['ob' + meal.variant]" clearable maxlength="30" counter variant="outlined" density="compact"></v-text-field>
                        <v-text-field v-model="meal.price" label="Cena" type="number" :error-messages="fieldErrors['ob' + meal.variant]" clearable variant="outlined" density="compact"></v-text-field>
                      </div>
                    </v-col>
                    <!-- Header for Dinner -->
                    <v-col cols="12" md="4">
                      <div class="text-center mb-3">Večeře</div>
                      <div v-for="meal in day.dinners" :key="meal.variant">
                        <v-text-field v-model="meal.name" :label="`Název (varianta ${meal.variant})`" :error-messages="fieldErrors['ve' + meal.variant]" clearable maxlength="30" counter variant="outlined" density="compact"></v-text-field>
                        <v-text-field v-model="meal.price" label="Cena" type="number" :error-messages="fieldErrors['ve' + meal.variant]" clearable variant="outlined" density="compact"></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <div style="display: flex; justify-content: flex-end; margin-top: 20px;">
                <v-btn color="success" @click="submitEntryService">Uložit stravu a ubytování</v-btn>
              </div>

            </v-form>

          </v-window-item>




    <!-- ***************************************
 *******************************************
 ********         SLUŽBY          **********
 *******************************************
 ****************************************-->


 <v-window-item value="services" v-if="isRace0">


    <!-- Sekce pro si_rent (půjčení čipu) -->
    <v-col v-if="si_rent && si_rent.length > 0" cols="12" md="2">
      Půjčení čipu:
      <div v-for="service in si_rent" :key="service.id">
        <v-text-field
          v-model="service.price"
          label="Cena za půjčení čipu"
          type="number"
          min="0"
          :error-messages="fieldErrors[`si_rent${service.id}`]"
          clearable
          hint ="Bez uvedené ceny není půjčení čipu nabízeno. 0 = zadarmo"
        ></v-text-field>
      </div>
    </v-col>

    <!-- Tlačítko pro uložení -->
    <div style="display: flex; justify-content: flex-end; margin-top: 10px;">
      <v-btn color="success" @click="submitEntryService">Uložit služby</v-btn>
    </div>

 </v-window-item>






<!-- ***************************************
 *******************************************
 ********       STARTOVKA         **********
 *******************************************
 ****************************************-->




          <v-window-item v-if="races.length === 0 || !isRace0" value="startlist">
            <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>

            <v-row class="justify-space-between">
                <v-col cols="12" md="3">
                  <v-btn @click="openStartlistStepperDialog" color="success">Nahrát startovku</v-btn>
                </v-col>

                <v-col cols="12" md="2" v-if="entries.length > 0">
                <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ props }">
                  <v-btn color="green" text v-bind="props">
                      Exporty {{  currentRaceId.value }}<v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item :href="`${BaseUrl}/export/?type=pdf&name=startlist_starter&race_id=${currentRaceId}`" v-if="startlist.length > 0"><v-list-item-title>Startovka startér</v-list-item-title></v-list-item>
                    <v-list-item :href="`${BaseUrl}/export/?type=csv&name=startlist&race_id=${currentRaceId}`" v-if="startlist.length > 0"><v-list-item-title>Startovka csv</v-list-item-title></v-list-item>
                    <v-list-item :href="`${BaseUrl}/export/?type=csv&name=si_droid_race&race_id=${currentRaceId}`"><v-list-item-title>SI Droid</v-list-item-title></v-list-item>
                    <v-list-item :href="`${BaseUrl}/export/?type=xml&name=livelox_startlist&race_id=${currentRaceId}`"><v-list-item-title>Livelox</v-list-item-title></v-list-item>
                  </v-list>
                </v-menu>
                </v-col>

                <v-col cols="12" md="3" v-if="startlist.length > 0">
                  <v-btn color="error" @click="openDeleteAllStartTimesDialog">Smazat startovku</v-btn>
                </v-col>
              </v-row>


              <!-- Dialog pro potvrzení smazání všech start_time -->
              <v-dialog v-model="dialogDeleteAllStartTimes" persistent max-width="400px">
                <v-card>
                  <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
                  <v-card-text>
                    Opravdu chcete smazat všechny startovní časy pro tento závod?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDeleteAllStartTimes = false">Zrušit</v-btn>
                    <v-btn color="red darken-1" text @click="deleteAllStartTimes">Smazat</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>


              <v-data-table
                v-if="isLoadingStartlist || startlist.length > 0"
                :items="startlist"
                :headers="GetStartlistHeaders" 
                item-key="index" 
                :items-per-page="100" 
                :sort-by="StartlistSortBy"
                @touchstart.stop 
                @touchmove.stop
                :loading="isLoadingStartlist"
                loading-text="Načítám data..."
                  >

                <template v-slot:[`item.first_name`]="{ item }">
                  {{ item.first_name }}
                </template>

                <template v-slot:[`item.last_name`]="{ item }">
                  {{ item.last_name }}
                </template>

                <template v-slot:[`item.competitor_start_time`]="{ item }">
                  {{ $formatTimeSec(item.competitor_start_time) || 'N/A' }}
                </template>

                <template v-slot:[`item.category_name`]="{ item }">
                  {{ item.category_name }}
                </template>

                <template v-slot:[`item.competitor_index`]="{ item }">
                  {{ item.competitor_index }}
                </template>

                <template v-slot:[`item.si_number`]="{ item }">
                  {{ item.si_number }}
                </template>

                <template v-slot:[`item.club_name`]="{ item }">
                  {{ item.club_name }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">

                  <v-list-item-action>
                  <!--<v-icon @click="openEditStartlistDialog(item)" color="blue" size="x-large" class="mr-2">mdi-pencil</v-icon>-->
                  <v-icon @click="openDeleteGetStartDialog(item)" color="red" size="x-large" title="smazat start">mdi-delete</v-icon>
                </v-list-item-action>
              </template>

              </v-data-table>

              <!-- Dialog pro potvrzení smazání Start -->
              <v-dialog v-model="dialogDeleteStart" persistent max-width="400px">
                <v-card>
                  <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
                  <v-card-text>
                    Chcete opravdu smazat start pro <b>{{ selectedStart.last_name }} {{ selectedStart.first_name }}</b>?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDeleteStart = false">Zrušit</v-btn>
                    <v-btn color="red darken-1" text @click="deleteGetStart">Smazat</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>


              <v-dialog v-model="dialogAddStartlist" max-width="1200px">
              <v-card>
                <v-icon
                  color="gray"
                  title="zavřít"
                  size="large"
                  @click="closeStartlistDialog"
                  class="ml-auto"
                  >mdi-close
                </v-icon>


                <v-card-text>
                  <v-stepper hide-actions v-model="stepStart" :items="['Nahraj startovku', 'Kontrola dat']">
                    <template v-slot:[`item.1`]>
                      <v-card>
                        <v-card-text>
                          Nahraj csv soubor v požadovaném formátu dat, kódování UTF-8.<br>
                          Startovní číslo;Příjmení;Jméno;kategorie;relativní čas startu;absolutní čas startu;index;volačka;stát;číslo čipu.<br>
                          Např. <code>3;Novák;Petr;M20;65:30;11:05:30;GBM8105;OK123;CZE;2045258</code>
                        </v-card-text>
                        <v-file-input
                          label="Vložit soubor"
                          outlined
                          dense
                          density="compact"
                          @change="handleFileUpload"
                          :error-messages="errorStartlistMessage"
                        ></v-file-input>

                        <v-btn
                          color="primary"
                          @click="uploadStartlistFile"
                          :disabled="!isFileValid"
                          class="mt-4"
                        >
                          Nahrát
                        </v-btn>

                        <span v-if="successStartlistMessage" style="color:green; font-size:11px;" class="ma-2">
                          {{ successStartlistMessage }}
                        </span>

                      </v-card>
                    </template>



                    <template v-slot:[`item.2`]>

                      <v-row class="mb-1">
                        <v-col cols="2" style="background:green">Vše OK</v-col>
                        <v-col cols="5" style="background:orange">V přihláškách ROBisu nejsou</v-col>
                        <v-col cols="5" style="background:red">Mají přihlášku, nemají start</v-col>

                      </v-row>
                        <v-data-table
                        :headers="startlistHeaders"
                        :items="combinedStarts"
                        item-key="index"
                        class="elevation-1"
                        density="comfortable"
                        items-per-page="-1"
                        :row-props="setRowProps"
                      >

                      <template v-slot:[`item.start_number`]="{ item }">
                          {{ item.start_number }}
                        </template>

                        <template v-slot:[`item.last_name`]="{ item }">
                          {{ item.last_name }}
                        </template>

                        <template v-slot:[`item.first_name`]="{ item }">
                          {{ item.first_name }}
                        </template>

                        <template v-slot:[`item.competitor_index`]="{ item }">
                          {{ item.competitor_index }}
                        </template>

                        <template v-slot:[`item.category_name`]="{ item }">
                          {{ item.category_name }}
                        </template>


                        <template v-slot:[`item.si_number`]="{ item }">
                          {{ item.si_number }}
                        </template>


                        <template v-slot:[`item.start_time_relative`]="{ item }">
                          {{ item.start_time_relative }}
                        </template>

                        <template v-slot:[`item.start_time_real`]="{ item }">
                          {{ item.start_time_real }}
                        </template>


                        <template v-slot:[`item.actions`]="{ item }">
                          <v-list-item-action>
                            <v-icon @click="openEditStartDialog(item)" color="blue" size="x-large" class="mr-2">mdi-pencil</v-icon>
                            <v-icon @click="openDeleteStartDialog(item)" color="red" size="x-large">mdi-delete</v-icon>
                          </v-list-item-action>
                        </template>
                        <template v-slot:bottom></template>
                      </v-data-table>

                      <!-- Zobrazení chybového hlášení -->
                      <div v-if="errorStartlistSendMessage.length" style="color:red; font-size:11px;" class="ma-2">
                          <div v-for="(error, index) in errorStartlistSendMessage" :key="index">
                            {{ error }}
                          </div>
                        </div>


                        <!-- Dialog pro editaci Start -->
                        <v-dialog v-model="dialogEditStart" persistent max-width="600px">
                          <v-card>
                            <v-card-title>
                              {{ selectedStart.last_name }} {{ selectedStart.first_name }}
                            </v-card-title>
                            <v-card-text>
                              <!-- <v-row>    Příjmení a jméno by se nemělo měnit kvůli přiřazoání
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="selectedStart.last_name"
                                  label="Příjmení"
                                  :rules="[v => !!v || 'Příjmení je povinné']"
                                  dense
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="selectedStart.first_name"
                                  label="Jméno"
                                  :rules="[v => !!v || 'Jméno je povinné']"
                                  dense
                                ></v-text-field>
                              </v-col>
                            </v-row> -->
                            <v-row>
                              <v-col cols="12" md="6">
                              <v-text-field v-model="selectedStart.competitor_index" hide-details label="Index" dense disabled></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                              <v-text-field v-model="selectedStart.category_name" hide-details label="Kategorie" dense></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="6">
                              <v-text-field v-model="selectedStart.start_number" hide-details type="number" label="Startovní číslo" dense></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="selectedStart.si_number" hide-details type="number" label="Číslo čipu" dense></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                              <v-col cols="12" md="6">
                              <v-text-field v-model="selectedStart.start_time_relative" :value="selectedStart.start_time_relative" hide-details type="time" step="2" label="Start relativní" dense></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="selectedStart.start_time_real" :value="selectedStart.start_time_real" hide-details type="time" step="2" label="Start reálný" dense></v-text-field>
                            </v-col>
                          </v-row>

                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="grey" text @click="dialogEditStart = false">Zrušit</v-btn>
                              <v-btn color="green darken-1" text @click="saveEditStart" :disabled="!selectedStart.first_name || !selectedStart.last_name">Uložit</v-btn>
                            </v-card-actions>

                          </v-card>
                        </v-dialog>

                        <!-- Dialog pro potvrzení smazání Start -->
                        <v-dialog v-model="dialogDeleteStart" persistent max-width="400px">
                          <v-card>
                            <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
                            <v-card-text>
                              Chcete opravdu smazat start pro <b>{{ selectedStart.last_name }} {{ selectedStart.first_name }}</b>?
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="dialogDeleteStart = false">Zrušit</v-btn>
                              <v-btn color="red darken-1" text @click="deleteStart">Smazat</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>


                      </template>


                    <v-stepper-actions>
                      <template v-slot:next>
                        <v-btn color="primary" v-if="stepStart < totalStartSteps" @click="stepStart++">Další</v-btn>
                        <v-btn color="green darken-1" v-else @click="saveStartData" :disabled="false">Uložit</v-btn>
                      </template>
                      <template v-slot:prev>
                        <v-btn variant="plain" v-if="stepStart > 1" @click="stepStart--">Zpět</v-btn>
                        <v-btn variant="plain" v-else>&nbsp;</v-btn>
                      </template>
                    </v-stepper-actions>




                  </v-stepper>
                </v-card-text>
              </v-card>
            </v-dialog>


          </v-window-item>


          <!-- ***************************************
          *******************************************
          ********        VÝSLEDKY         **********
          *******************************************
          ****************************************-->


          <v-window-item value="results" v-if="races.length === 0 || !isRace0">

            <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>

            <v-row class="justify-space-between">
                <v-col cols="12" md="3">
                  <v-btn @click="openResultsStepperDialog" color="success">Nahrát výsledky</v-btn>
                </v-col>

                <v-col cols="12" md="2" v-if="results.length > 0">
                <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ props }">
                  <v-btn color="green" text v-bind="props">
                      Exporty {{  currentRaceId.value }}<v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item :href="`${BaseUrl}/export/?type=csv&name=results&race_id=${currentRaceId}`" v-if="results.length > 0"><v-list-item-title>Výsledky csv</v-list-item-title></v-list-item>
                    <!-- <v-list-item :href="`${BaseUrl}/export/?type=xml&name=livelox_startlist&race_id=${currentRaceId}`"><v-list-item-title>Livelox</v-list-item-title></v-list-item> -->
                  </v-list>
                </v-menu>
                </v-col>

                <v-col cols="12" md="3" v-if="results.length > 0">
                  <v-btn color="error" @click="openDeleteAllResultsDialog">Smazat výsledky</v-btn>
                </v-col>
              </v-row>


              <!-- Dialog pro potvrzení smazání všech start_time -->
              <v-dialog v-model="dialogDeleteAllResults" persistent max-width="400px">
                <v-card>
                  <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
                  <v-card-text>
                    Opravdu chcete smazat všechny startovní časy pro tento závod?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDeleteAllResults = false">Zrušit</v-btn>
                    <v-btn color="red darken-1" text @click="deleteAllResults">Smazat</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>


              <v-data-table
                v-if="isLoadingResults || results.length > 0"
                :items="results"
                :headers="GetResultsHeaders" 
                item-key="index" 
                items-per-page="-1" 
                :sort-by="ResultsSortBy"
                @touchstart.stop 
                @touchmove.stop
                :loading="isLoadingResults"
                loading-text="Načítám data..."
                  >

                <template v-slot:[`item.place`]="{ item }">
                  {{ item.place }}
                </template>

                <template v-slot:[`item.first_name`]="{ item }">
                  {{ item.first_name }}
                </template>

                <template v-slot:[`item.last_name`]="{ item }">
                  {{ item.last_name }}
                </template>

                <template v-slot:[`item.run_time`]="{ item }">
                  {{ item.run_time }}
                </template>

                <template v-slot:[`item.result_status`]="{ item }">
                  {{ item.result_status }}
                </template>

                <template v-slot:[`item.punch_count`]="{ item }">
                  {{ item.punch_count }}
                </template>


                <template v-slot:[`item.category_name`]="{ item }">
                  {{ item.category_name }}
                </template>

                <template v-slot:[`item.competitor_index`]="{ item }">
                  {{ item.competitor_index }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">

                  <v-list-item-action>
                  <!--<v-icon @click="openEditStartlistDialog(item)" color="blue" size="x-large" class="mr-2">mdi-pencil</v-icon>-->
                  <v-icon @click="openDeleteGetResultDialog(item)" color="red" size="x-large" title="smazat start">mdi-delete</v-icon>
                </v-list-item-action>
              </template>

              </v-data-table>

              <!-- Dialog pro potvrzení smazání Start -->
              <v-dialog v-model="dialogDeleteResult" persistent max-width="400px">
                <v-card>
                  <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
                  <v-card-text>
                    Chcete opravdu smazat start pro <b>{{ selectedResult.last_name }} {{ selectedResult.first_name }}</b>?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDeleteResult = false">Zrušit</v-btn>
                    <v-btn color="red darken-1" text @click="deleteGetResult">Smazat</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>


              <v-dialog v-model="dialogAddResults" max-width="1200px">
              <v-card>
                <v-icon
                  color="gray"
                  title="zavřít"
                  size="large"
                  @click="closeResultsDialog"
                  class="ml-auto"
                  >mdi-close
                </v-icon>


                <v-card-text>
                  <v-stepper hide-actions v-model="stepResults" :items="['Nahraj výsledky', 'Kontroly', 'Výsledky']">
                    <template v-slot:[`item.1`]>
                      <v-card>
                        <v-card-text class="mb-3 px-0">
                          Specifikace a formát dat jsou dostupné v <a :href="`${BaseUrl}/napoveda#vysledky`" target="_blank" rel="noopener noreferrer">nápovědě</a>.
                        </v-card-text>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-select 
                            :items="[
                                { title: 'CSV', value: 'csv' },
                                { title: 'OCM CSV', value: 'csv_ocm' },
                                { title: 'XML IOF 3.0', value: 'xml' }
                              ]"
                              item-text="title" 
                              item-value="value" 
                              v-model="selectedFileType" 
                              label="Typ souboru" 
                              density="compact"
                              :rules="[v => !!v || 'Vyber typ']"
                              :error-messages="errorResultsSelectMessage"
                              @change="validateSelectedFileType"
                              >
                            </v-select>  
                          </v-col>  
                          <v-col cols="12" md="8">                    
                            <v-file-input
                              label="Vložit soubor"
                              density="compact"
                              @change="handleResultsFileUpload"
                              :error-messages="errorResultsMessage"
                            ></v-file-input>
                          </v-col>
                        </v-row>

                        <v-btn
                          color="primary"
                          @click="uploadResultsFile"
                          :disabled="!isResultsFileValid"
                          class="mt-4"
                          v-if="!loadingSubmit"
                        >
                          Nahrát
                        </v-btn>
                        <v-progress-circular class="mt-4" v-else indeterminate color="primary"></v-progress-circular>

                        <span v-if="successResultsMessage" style="color:green; font-size:11px;" class="ma-2">
                          {{ successResultsMessage }}
                        </span>

                      </v-card>
                    </template>

                    <template v-slot:[`item.2`]>

                      <v-row v-for="(category, index) in categoryProperties" :key="index" class="my-2 mr-1">
                        <!-- Název kategorie -->
                        <v-col cols="12" md="2" class="d-flex justify-end align-right text-h6 py-2">
                          {{category.category_name}}
                        </v-col>
                        <!-- Editovatelné kontroly -->
                        <v-col cols="12" md="10" class="pa-2">
                          <v-text-field
                            v-model="category.category_control_points"
                            label="Kontroly (oddělené čárkou)"
                            variant="outlined"
                            dense
                            hide-details
                            density="compact"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                    </template>



                    <template v-slot:[`item.3`]>

                      <v-row class="mb-1">
                        <v-col cols="4" style="background:green">Výsledky byly spárovány s přihláškami</v-col>
                        <v-col cols="4" style="background:orange">Tito nejsou v Robisu na soutěž přihlášeni. Budou vytvořeni.</v-col>
                        <v-col cols="4" style="background:red">Mají v ROBisu přihlášku, ale neukládáš jim výsledek.</v-col>
                      </v-row>
                      <v-col cols="12">
                        Poslal jsi <b>{{ resultsEntryOk.length + resultsEntryCreate.length }}</b> výsledků, celkem zobrazeno <b>{{ resultsEntryOk.length + resultsEntryCreate.length + resultsEntryEmpty.length }}</b>
                      </v-col>

                      <v-data-table
                        :headers="resultsHeaders"
                        :items="combinedResults"
                        item-key="index"
                        class="elevation-1"
                        density="comfortable"
                        items-per-page="-1"
                        :row-props="setResultsRowProps"
                        :sort-by="ResultsSortBy"
                      >

                        <template v-slot:[`item.place`]="{ item }">
                          {{ item.place }}
                        </template>

                        <template v-slot:[`item.last_name`]="{ item }">
                          {{ item.last_name }}
                        </template>

                        <template v-slot:[`item.first_name`]="{ item }">
                          {{ item.first_name }}
                        </template>

                        <template v-slot:[`item.competitor_category_name`]="{ item }">
                          {{ item.competitor_category_name }}
                        </template> 
                        
                        <template v-slot:[`item.competitor_index`]="{ item }">
                          {{ item.competitor_index }}
                        </template>

                        <template v-slot:[`item.run_time`]="{ item }">
                          {{ item.run_time }}
                        </template>


                        <template v-slot:[`item.actions`]="{ item }">
                          <v-list-item-action>
                            <v-icon @click="openEditResultDialog(item)" color="blue" size="x-large" class="mr-2">mdi-magnify</v-icon>
                            <v-icon @click="openDeleteResultDialog(item)" color="red" size="x-large">mdi-delete</v-icon>
                          </v-list-item-action>
                        </template>
                        <template v-slot:bottom></template>
                      </v-data-table>

                      <!-- Zobrazení chybového hlášení -->
                      <div v-if="errorResultsSendMessage.length" style="color:red; font-size:13px; float:right;" class="ma-2">
                          <div v-for="(error, index) in errorResultsSendMessage" :key="index">
                            {{ error }}
                          </div>
                        </div>


                        <!-- Dialog pro editaci Result -->
                        <v-dialog v-model="dialogEditResult" max-width="800px">
                          <v-card>
                            <v-card-title>
                              {{ selectedResult.last_name }} {{ selectedResult.first_name }}
                            </v-card-title>
                            <v-card-text>
                            <v-row>
                              <v-col cols="12" md="4">
                                <v-text-field v-model="selectedResult.start_number" hide-details type="number" disabled label="Startovní číslo"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                              <v-text-field v-model="selectedResult.competitor_category_name" hide-details disabled label="Kategorie"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-select
                                  v-model="selectedResult.result_status"
                                  :items="[
                                    { title: 'OK', value: 'OK' },
                                    { title: 'Nestartoval/a', value: 'DNS' },
                                    { title: 'Nedokončil/a', value: 'DNF' },
                                    { title: 'Diskvalifikován/a', value: 'DSQ' },
                                    { title: 'Přesáhl/a limit', value: 'OVT' },
                                    { title: 'Nehodnocen/a', value: 'NR' },
                                    { title: 'Mimo soutěž', value: 'UNF' },
                                    { title: 'Špatné ražení', value: 'MP' },
                                    { title: 'Chyba ve výsledku', value: 'ERR' },
                                  ]"
                                  label="Status výsledku"
                                  hide-details
                                  disabled
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-text-field v-model="selectedResult.run_time" :value="selectedResult.run_time" hide-details label="Čas běhu (mm:ss)" disabled></v-text-field>
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-text-field v-model="selectedResult.punch_count" hide-details type="number" label="Počet kontrol" disabled></v-text-field>
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-text-field v-model="selectedResult.place" hide-details label="Umístění" disabled></v-text-field>
                              </v-col>
                            </v-row>

                            <v-col cols="12" class="mb-1">Mezičasy:</v-col>
                            <v-row v-for="(punch, punchIndex) in selectedResult.punches" :key="punchIndex" class="mb-1">
                              <v-col cols="3" class="py-1">
                                <v-text-field
                                  v-model="punch.code"
                                  label="Kód"
                                  hide-details
                                  density="compact"
                                  variant="outlined"
                                  disabled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="3" class="py-1">
                                <v-text-field
                                  v-model="punch.split_time"
                                  label="Mezičas"
                                  hide-details
                                  density="compact"
                                  variant="outlined"
                                  disabled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="3" class="py-1">
                                <v-text-field
                                  v-model="punch.alias"
                                  label="Alias"
                                  hide-details
                                  density="compact"
                                  variant="outlined"
                                  disabled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="3" class="py-1">
                                <v-select
                                  v-model="punch.punch_status"
                                  :items="[
                                    { title: 'OK', value: 'OK' },
                                    { title: 'Špatné ražení', value: 'MP' },
                                    { title: 'Duplikované ražení', value: 'DP' },
                                    { title: 'Navíc', value: 'AP' },
                                  ]"
                                  label="Status oražení"
                                  hide-details
                                  density="compact"
                                  variant="outlined"
                                  disabled
                                ></v-select>
                              </v-col>
                            </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="grey" text @click="dialogEditResult = false">Zavřít</v-btn>
                              <!-- <v-btn color="green darken-1" text @click="saveEditResult" :disabled="!selectedResult.first_name || !selectedResult.last_name">Uložit</v-btn> -->
                            </v-card-actions>

                          </v-card>
                        </v-dialog>

                        <!-- Dialog pro potvrzení smazání Result -->
                        <v-dialog v-model="dialogDeleteResult" persistent max-width="400px">
                          <v-card>
                            <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
                            <v-card-text>
                              Chcete opravdu smazat výsledek pro <b>{{ selectedResult.last_name }} {{ selectedResult.first_name }}</b>?
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="dialogDeleteResult = false">Zrušit</v-btn>
                              <v-btn color="red darken-1" text @click="deleteResult">Smazat</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>


                      </template>


                    <v-stepper-actions>
                      <template v-slot:next>
                        <v-progress-circular v-if="loadingSubmit && stepResults == totalResultsSteps" indeterminate color="primary"></v-progress-circular>
                        <v-btn color="primary" v-if="stepResults < totalResultsSteps" @click="stepResults++">Další</v-btn>
                        <v-btn color="green darken-1" v-else  @click="saveResultsData" :disabled="loadingSubmit">Uložit</v-btn>
                      </template>
                      <template v-slot:prev>
                        <v-btn variant="plain" v-if="stepResults > 1" @click="stepResults--">Zpět</v-btn>
                        <v-btn variant="plain" v-else>&nbsp;</v-btn>
                      </template>
                    </v-stepper-actions>




                  </v-stepper>
                </v-card-text>
              </v-card>
            </v-dialog>






          </v-window-item>
      
        </v-window>
      </v-card-text>


    </v-card>

  </v-container>
</template>






<script setup>
import { ref, computed, onMounted, watch, reactive } from 'vue';
import apiClient from '/api.js';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '/auth.js';
import { format } from 'date-fns';


const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();


const event = ref({
  entry_is_open: false,
  entry_deadline_1: ""
});
const eventTypes = ref([
  { title: 'Závody', value: 'races' },
  { title: 'Soustředění', value: 'camp' },
  { title: 'Trénink', value: 'training' },
  { title: 'Ostatní', value: 'other' },


  // { title: 'Reprezentační', value: 'repre' },
  // { title: 'SCM', value: 'scm' },
  // { title: 'Asociační', value: 'association' }
]);

const visibilityTypes = ref([
  { title: 'Veřejná', value: 'PUBLIC' },
  { title: 'Pouze oddíl', value: 'CLUB' },
  ]);

const races = ref([]); // Seznam etap (Race) získaných z API kromě prvního Race
const race0 = ref({});
const currentRaceId = ref(null); // Zde uchováváme aktuálně vybrané ID závodu
const files = ref([]);
const showAddFileSection = ref(false); // Řídí zobrazení sekce pro přidání nového souboru
const showAddLinkSection = ref(false); // Zobrazit pole pro nový odkaz
const newFile = ref({ name: '', specific: null, location: null }); // Data pro nový soubor
const newLink = ref({ name: '', url: '' }); // data pro nový odkaz
const fileTypes = ref([
  { title: 'Propozice', value: 'bulletin' },
  { title: 'Pokyny', value: 'info' },
  { title: 'Startovka', value: 'startlist' },
  { title: 'Výsledky', value: 'results' }
]);
const newRace = ref({ race_name: '', race_date: '', race_level: '' });
const raceLevel = [
  { title: 'MČR/NŽ - I. stupeň', value: 'NATIONAL' },
  { title: 'Soutěž II. stupně', value: 'REGIONAL' },
  { title: 'Soutěž III. stupně', value: 'DISTRICT' },
  { title: 'Trénink', value: 'TRAINING' },
  { title: 'Ostatní', value: 'OTHER' },
];

const raceType= [
  { title: 'Klasika', value: 'CLASSIC' },
  { title: 'Sprint', value: 'SPRINT' },
  { title: 'Foxoring', value: 'FOXORING' },
  { title: 'Dlouhá trať', value: 'LONG' },
  { title: 'Štafety', value: 'RELAY' },
];


const race_bands = ref([
  { title: '3,5 MHz', value: 'M80' },
  { title: '144 MHz', value: 'M2' },
  { title: '144 MHz + 3,5 MHz', value: 'COMBINED' },
]);


const categories = ref([]); // Seznam kategorií pro zvolený Race nebo pro Race0 v rámci Eventu
const categoriesToDelete = ref([]);

const categoryHeaders = ref([ // Hlavičky tabulky pro kategorie
  { title: 'Kategorie', key: 'option' },
  { title: 'Název', key: 'category_name' },
  { title: 'Startovné', key: 'fee' },
  { title: 'Zvýšené startovné', key: 'increased_fee' },
  { title: 'Startovné soutěže', key: 'fee_event' },
  { text: 'Akce', value: 'actions', sortable: false }
]);

const categoryHeadersEvents = ref([ // Hlavičky tabulky pro kategorie
  { title: 'Kategorie', key: 'option' },
  { title: 'Název', key: 'category_name' },
  { title: 'Startovné', key: 'fee' },
  { title: 'Zvýšené startovné', key: 'increased_fee' },
  { text: 'Akce', value: 'actions', sortable: false }
]);


const main = ref('event'); // Přidáno pro inicializaci na první záložku main části
const second = ref('entries'); // Přidáno pro inicializaci na první záložku second části
const BaseUrl = ref(process.env.VUE_APP_API_BASE_URL.replace(/\/+$/, '').replace(/\/api\/?$/, '')); // Odstraní "api/" a nadbytečná lomítka
const successMessage = ref('');
const errorMessage = ref('');
const dialogDeleteEvent = ref(false);
const dialogDeleteRace = ref(false);
const dialogAddRace = ref(false);
const dialogCategory = ref(false);
const dialogCategoryRace = ref(false);
const change_allcategories = ref(false);
const dialogCategoryRace0 = ref(false);
const dialogDeleteFile = ref(false);

const CategoryType = ref([
        { title: 'D7 (0-7)', value: 'W7' },
        { title: 'D9 (0-9)', value: 'W9' },
        { title: 'D12 (0-12)', value: 'W12' },
        { title: 'D14 (0-14)', value: 'W14' },
        { title: 'D16 (0-16)', value: 'W16' },
        { title: 'D19 (0-19)', value: 'W19' },
        { title: 'D20 (0-99)', value: 'W21' },
        { title: 'D35 (35-99)', value: 'W35' },
        { title: 'D45 (45-99)', value: 'W45' },
        { title: 'D55 (55-99)', value: 'W55' },
        { title: 'D65 (65-99)', value: 'W65' },
        { title: 'M7 (0-7)', value: 'M7' },
        { title: 'M9 (0-9)', value: 'M9' },
        { title: 'M12 (0-12)', value: 'M12' },
        { title: 'M14 (0-14)', value: 'M14' },
        { title: 'M16 (0-16)', value: 'M16' },
        { title: 'M19 (0-19)', value: 'M19' },
        { title: 'M20 (0-99)', value: 'M21' },
        { title: 'M40 (40-99)', value: 'M40' },
        { title: 'M50 (50-99)', value: 'M50' },
        { title: 'M60 (60-99)', value: 'M60' },
        { title: 'M70 (70-99)', value: 'M70' },
        { title: 'MDR (0-13)', value: 'MDR' },
        { title: 'Ostatní (0-99)', value: 'OTHERS' },
]);

 // error hlášky
const eventTitle = ref([v => !!v || 'Název je povinný']);
const eventDate = ref([v => !!v || 'Datum je povinné']);
const eventType = ref([v => !!v || 'Typ je povinný']);
const visibilityType = ref([v => !!v || 'Typ je povinný']);


const nameRules = ref([v => !!v || 'Název je povinný']);
// const category_max_ageRules = ref([
//   v => !!v || 'Max věk je povinný',
//   v => (v && v > 0) || 'Věk musí být větší než 0'
// ]);
// const sexRules = ref([v => !!v || 'Pohlaví je povinné']);
const optionRules = ref([v => !!v || 'Musíš vybrat typ']);

// const fileNameRules = ref([v => !!v || 'Název souboru je povinný']);
// const linkNameRules = ref([v => !!v || 'Název odkazu je povinný']);


// const formatDateTimeForInput = (datetime) => {
//   return datetime ? datetime.replace('Z', '').substring(0, 16) : '';
// };

// const parseDateTimeFromInput = (datetime) => {
//   return datetime ? datetime + ':00' : '';
// };


// Oprávnění organizátora, závisí na tom, jestli je organizátor ve stejném klubu jako event nebo je editor
const isOrganizer = computed(() => {
  // Převod Proxy objektu na běžné pole pro jednoduchý přístup
  const rolesArray = Array.from(authStore.roles);
  const isRoleOrganizer = rolesArray.includes('organizer');
  const isSameClub = Number(authStore.userClub) === Number(event.value.club);
  // Podmínky pro editora
  const editorsArray = event.value.editors || [];
  const isUserEditor = editorsArray.includes(Number(authStore.userID));
  const isAuthor = Number(event.value.author) === Number(authStore.userID);

  // Oprávnění pro organizátora nebo když je editors prázdné
  console.log('Is Organizer:', isRoleOrganizer, 'Is Same Club:', isSameClub, 'Is User Editor:', isUserEditor);
  return isAuthor || isUserEditor || (editorsArray.length === 0 && isRoleOrganizer && isSameClub); // user je editor nebo pokud nejsou editoři, je organozátor
});



// Sledujte změnu v aktuálně vybrané záložce a mění Race ID
watch(main, (newValue) => {
  if (newValue === 'event' && race0.value.id) {
    currentRaceId.value = race0.value.id;
  }
});


const isRace0 = computed(() => currentRaceId.value === race0.value.id); // zjištění, jestli se jedná o hlavní stránku, potom zobraz přihlášené a Raceservices v template

// Filtrování souborů pro zobrazení pouze odkazů
const filesWithLinks = computed(() => files.value.filter(file => file.is_link));
// Filtrování souborů pro zobrazení pouze souborů
const filesWithLocation = computed(() => files.value.filter(file => file.is_file));

onMounted(async () => {
  await loadEventAndRacesData(); // Načtení dat eventu a etap
  checkForLocalMessages(); // zkontroluje, jestli není potřeba načíst success message po úspěšném uložení soutěže
  await fetchRaceServiceData();
  await fetchEntries();
  fetchEditors();
  updateSecondTab(); // načtení aktivní záložky podle dostupných dat

});

// nastavení podle čeho se má zobrazit aktivní záložka v soutěži 
const updateSecondTab = () => {
  console.log(`Entries: ${entries.value.length}, Startlist: ${startlist.value.length}, Results: ${results.value.length}`);

  if (results.value.length > 0) {
    second.value = 'results';
  } else if (startlist.value.length > 0) {
    second.value = 'startlist';
  } else if (entries.value.length > 0) {
    second.value = 'entries';
  } else {
    second.value = 'acc_food';
  }
};


const checkForLocalMessages = () => {
  const message = localStorage.getItem('successMessage');
  if (message) {
    successMessage.value = message;
    localStorage.removeItem('successMessage'); // Odstranění zprávy po jejím zobrazení
  }
};


const loadEventAndRacesData = async () => {
  const eventId = route.params.id; // event ID z URL adresy
  try {
    const response = await apiClient.get(`event/edit/?id=${eventId}`);
    event.value = response.data;

    // Seřadit závody podle ID
    const sortedRaces = response.data.races.sort((a, b) => a.id - b.id);
    // uloží hodoty pro první race0
    race0.value = sortedRaces.length > 0 ? sortedRaces[0] : null;
    // Odstranit první závod (s nejnižším ID)
    const racesWithoutFirst = sortedRaces.slice(1);
    // Uložit upravené závody do races v pořadí podle race_date a race_start
    races.value = racesWithoutFirst.sort((a, b) => {
      if (a.race_date < b.race_date) return -1;
      if (a.race_date > b.race_date) return 1;
      if (a.race_start < b.race_start) return -1;
      return a.race_start > b.race_start ? 1 : 0;
    });


    // allRaces data pro editaci přihlášených
    allRaces.value = response.data.races.map((race, competitor_index) => {
      if (competitor_index !== 0) {
        race.categories.push({
          id: null,
          option: 'nepřihlášen',
          category_name: 'nepřihlášen',
          race: race.id
        });
      }
      race.categories.sort((a, b) => {
        if (a.category_name === 'nepřihlášen') return -1;
        if (b.category_name === 'nepřihlášen') return 1;
        return a.category_name.localeCompare(b.category_name);
      });
      return race;
    });

    // Seřadit allRaces podle ID
    allRaces.value = allRaces.value.sort((a, b) => a.id - b.id);
    // Uložit první závod a ostatní závody do allRaces v pořadí podle race_date a race_start
    allRaces.value = [
      allRaces.value[0],
      ...allRaces.value.slice(1).sort((a, b) => {
        const dateComparison = new Date(a.race_date) - new Date(b.race_date);
        if (dateComparison !== 0) return dateComparison;
        if (!a.race_start) return 1;
        if (!b.race_start) return -1;
        return a.race_start.localeCompare(b.race_start);
      })
    ];

    // Po vytvoření race0 se toto id přenese do fetchCategories atd., aby se kategorie race0 načetli při otevření nastavení, když je vybraný Event
    if (race0.value && race0.value.id) {
      currentRaceId.value = race0.value.id;
      // načtení race0 ID po otevření nastavení. Poté se race ID aktualizuje podle kliknutí na záložku podle watcheru
      await fetchCategories(race0.value.id);
      await fetchFiles(race0.value.id);
    }
  } catch (error) {
    console.error("There was an error fetching the event and races data:", error);
  }
};

// aktualizace startu 00 pro race i race0
const updaterace_startTime = (race) => {
  const raceData = race || race0; // Použij race, pokud je k dispozici, jinak race0
  if (!raceData.race_startTime) {
    raceData.race_start = null; // Nastavit na null, pokud je input prázdný
  } else {
    const timeValue = raceData.race_startTime.trim();
    const isValidTime = /^([01]\d|2[0-3]):?([0-5]\d)$/.test(timeValue);

    if (isValidTime) {
      raceData.race_start = `${raceData.race_date}T${timeValue}`;
    } else {
      console.error("Invalid time format:", timeValue);
      raceData.race_start = null; // Nebo zobrazit chybu uživateli
    }
  }
};


const editors = ref([]);
const selectedEditors = ref([]);

// Načítání účtů z API
const fetchEditors = async () => {
  try {
    const response = await apiClient.get('account/?list=true'); // Volání API s parametrem simple=true
    editors.value = response.data;

    // Přiřaď aktuální editory na základě jejich ID z eventu
    selectedEditors.value = editors.value.filter(editor =>
      event.value.editors.includes(editor.id)
    );

  } catch (error) {
    console.error('Chyba při načítání účtů:', error);
  }
};



const validateEntryDeadlines = () => {
  const deadline1 = new Date(race0.value.entry_deadline_1);
  const deadline2 = race0.value.entry_deadline_2 ? new Date(race0.value.entry_deadline_2) : null;
  const eventStartDate = new Date(event.value.event_date_start);

  // Reset time to 00:00:00 for comparison
  const resetTime = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };

  const deadline1DateOnly = resetTime(deadline1);
  const deadline2DateOnly = deadline2 ? resetTime(deadline2) : null;
  const eventStartDateOnly = resetTime(eventStartDate);

  if (deadline1DateOnly > eventStartDateOnly) {
    errorMessage.value = "1. termín přihlášek musí být před datem nebo mít stejné datum jako datum začátku soutěže.";
    successMessage.value = "";
    return false;
  }

  if (deadline2DateOnly && deadline2DateOnly > eventStartDateOnly) {
    errorMessage.value = "2. termín přihlášek musí být před datem nebo mít stejné datum jako datum začátku soutěže.";
    successMessage.value = "";
    return false;
  }

  if (deadline2DateOnly && deadline2DateOnly < deadline1DateOnly) {
    errorMessage.value = "2. termín přihlášek nesmí být před datem 1. termínu přihlášek.";
    successMessage.value = "";
    return false;
  }

  return true;
};



  const saveEvent = async () => {
  const eventId = route.params.id;

  // Check if entry is open and the first entry deadline for race0 is not set
  if (event.value.entry_is_open === true && (race0.value.entry_deadline_1 === null || race0.value.entry_deadline_1 === '')) {
    errorMessage.value = "Soutěž nelze uložit, protože při spuštění přihlášek musí být známý I. termín přihlášek pro soutěž.";
    console.error(errorMessage.value);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    return; // Předčasné ukončení metody, nepokračovat v uložení
  }

  // Validace termínů přihlášek
  if (!validateEntryDeadlines()) {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    return;
  }

  // Kontrola, zda datum všech etap jsou v rozmezí dat začátku a konce události
  const startDate = new Date(event.value.event_date_start);
  const endDate = new Date(event.value.event_date_end);

  for (let race of races.value) {
    const raceDate = new Date(race.race_date);
    if (raceDate < startDate || raceDate > endDate) {
      errorMessage.value = 'Datum všech etap musí být v rozmezí konání soutěže.';
      return;
    }
  }


  // Aktualizace race_start s přidáním času z inputu
  for (let race of races.value) {
    if (race.race_startTime === undefined) {
      // Pokud nebyl zadán žádný čas, ponecháme původní hodnotu
      continue;
    } else if (!race.race_startTime || race.race_startTime === '') {
      race.race_start = null; // Nastavit na null, pokud není zadán žádný čas
    } else {
      race.race_start = `${race.race_date}T${race.race_startTime}`;
    }
  }
  // Kontrola, zda race.race_start obsahuje stejné datum jako race.race_date
  for (let race of races.value) {
    if (race.race_start && race.race_date) {
      const race_startDate = race.race_start.split('T')[0];
      if (race_startDate !== race.race_date) {
        errorMessage.value = 'Datum ve Startu 00 musí být stejné, jako datum etapy.';
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
    }
  }

  // Přiřazení ID editorů do eventu před odesláním na server
  event.value.editors = selectedEditors.value.filter(editor => editor !== null && typeof editor === 'object' && 'id' in editor).map(editor => editor.id);


  try {
    const response = await apiClient.put(`event/edit/?id=${eventId}`, event.value);
    event.value = response.data;
    localStorage.setItem('successMessage', 'Soutěž byla úspěšně aktualizována');
    router.go(`/soutez/${eventId}/nastaveni`); // aktualizace stránky
    } catch (error) {
    console.error("There was an error updating the event:", error);

    if (error.response && error.response.status === 423) {
      // Pokud status 423, zobraz zprávu z API
      errorMessage.value = error.response.data.error || "Nelze aktualizovat událost kvůli blokovanému stavu.";
    } else {
      // Obecná chybová zpráva
      errorMessage.value = "Nepodařilo se aktualizovat událost.";
    }

    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    }
  };

const deleteEvent = async () => {
  try {
    const eventId = route.params.id;
    await apiClient.delete(`event/edit/?id=${eventId}`);
    dialogDeleteEvent.value = false; // Close the confirmation dialog
    localStorage.setItem('successMessage', 'Soutěž byla úspěšně smazána.');
    router.push('/'); // Přesměrování uživatele po úspěšném smazání
  } catch (error) {
    console.error("There was an error deleting the race:", error);
    dialogDeleteEvent.value = false; // Close the confirmation dialog
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    successMessage.value = "";
    if (error.response.status === 423) {
      errorMessage.value = "Nemůžete smazat soutěž, ve které jsou přihlášení závodníci.";
    } else {
      errorMessage.value = "Etapu se nepodařilo smazat.";
    }
  }
};


  const addRace = async () => {
  try {
    const eventId = route.params.id;

    // Kontrola, zda datum závodu je v rozmezí dat začátku a konce události
    const startDate = new Date(event.value.event_date_start);
    const endDate = new Date(event.value.event_date_end);
    const raceDate = new Date(newRace.value.race_date);

    if (raceDate < startDate || raceDate > endDate) {
      errorMessage.value = 'Datum etapy musí být v rozmezí konání soutěže.';
      return;
    }


    const newRaceData = {
      race_name: newRace.value.race_name,
      race_date: newRace.value.race_date,
      race_level: newRace.value.race_level,
      event: route.params.id, // ID eventu, ke kterému se etapa přidává
    };
    await apiClient.post(`event/edit/?id=${eventId}`, newRaceData);
    await loadEventAndRacesData();
    newRace.value = {race_name: '', race_date: '', race_level: ''}; // Resetování formuláře
    successMessage.value = "Etapa byla úspěšně přidána.";
    errorMessage.value = "";
    dialogAddRace.value = false;

  } catch (error) {
    console.error("There was an error adding the race:", error);
    errorMessage.value = "Všechny údaje musí být vyplněné.";
    dialogAddRace.value = true;
  }
};

// Zde se odesílá DELETE požadavek na API
const deleteRace = async (eventId, raceId) => {
  try {
    await apiClient.delete(`event/edit/?race_id=${raceId}`);
    // Úspěšné smazání etapy
    successMessage.value = "Etapa byla úspěšně smazána.";
    await loadEventAndRacesData(); // Znovu načíst data pro aktualizaci UI
  } catch (error) {
    dialogDeleteRace.value = false; // zavřít okno upozornění
    console.error("There was an error deleting the race:", error);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    successMessage.value = "";
    if (error.response.status === 423) {
      errorMessage.value = "Nemůžete smazat etapu, ve které jsou přihlášení závodníci.";
    } else {
      errorMessage.value = "Etapu se nepodařilo smazat.";
    }
  }
};


// watcher na sledování v-model záložek pro event a races a následné načtení dat z api
watch(main, async (newTab) => {
  console.log(`Aktuální záložka: ${newTab}`);
  if (newTab === 'event') {
    currentRaceId.value = race0.value.id;
    // Vymazání startlistu a výsledků, protože pro event je nechceme zobrazovat
    startlist.value = [];
    results.value = [];
    
    await fetchFiles(race0.value.id);
    await fetchCategories(race0.value.id);

    console.log('Načítám data pro hlavní záložku.');
  } else if (newTab.startsWith('race-')) {
    const raceId = Number(newTab.split('-')[1]); // Získání race ID ze záložky
    currentRaceId.value = raceId;
    await fetchFiles(raceId);
    await fetchResults(raceId);
    await fetchStartlist(raceId);
    await fetchCategories(raceId);

  }

  updateSecondTab(); // načtení aktivní záložky podle dostupných dat ve všech případech

});




const fetchCategories = async (raceId) => {
  try {
    const response = await apiClient.get(`categories/?race=${raceId}`);
    categories.value = response.data.map(category => ({
      ...category,
      // K výpisu se přidá příznak pro hromadnou úpravu startovného, aby se neupravovaly hodnoty z databáze, ale jen ty prázdné
      feeFromDatabase: category.fee !== null && category.fee !== '',
      increasedFeeFromDatabase: category.increased_fee !== null && category.increased_fee !== '',
      feeEventFromDatabase: category.fee_event !== null && category.fee_event !== ''
    }));
  } catch (error) {
    console.error("There was an error fetching the categories:", error);
  }
};

// Aktivace tlačítka uložit, jen pokud je vyplněné pole name a option
const NotNameOption = () => {
  return categories.value.every(category => category.category_name.trim() !== '' && category.option.trim() !== '');
};

const bulkFee = ref(null);
const bulkIncreasedFee = ref(null);
const bulkFeeEvent = ref(null);


// Hromadně zvýšit startovné
const applyBulkFee = () => {
  categories.value.forEach(category => {
    if (!category.feeFromDatabase) {
      category.fee = bulkFee.value;
    }
  });
};

// Hromadně zvýšit zvýšené startovné
const applyBulkIncreasedFee = () => {
  categories.value.forEach(category => {
    if (!category.increasedFeeFromDatabase) {
      category.increased_fee = bulkIncreasedFee.value;
    }
  });
};

// Hromadně zvýšit startovné soutěže
const applyBulkFeeEvent = () => {
  categories.value.forEach(category => {
    if (!category.feeEventFromDatabase) {
      category.fee_event = bulkFeeEvent.value;
    }
  });
};


const addNewCategory = () => {

  const newCategory = {
    id: null, // ID nebude nastaveno pro nově vytvořenou kategorii
    category_name: '',
    category_max_age: null,
    category_gender: '',
    option: '',
    fee: null,
    increased_fee: null,
    reduced_fee: null,
    race: currentRaceId.value, // race je na základě aktuálního vybraného race.id
  };
  categories.value.push(newCategory); // Add the new category to the reactive list
};


// Pokud je switch 'Přepsat i v etapách' true, zobrazí se dialog pro potvrzení
const dialogCategoryRace0show = () => {
  if (change_allcategories.value) {
    dialogCategoryRace0.value = true;
  } else {
    updateCategory();
  }
};

const updateCategory = async () => {
  try {
    const eventId = route.params.id;
    const updatedCategories = categories.value.filter(c => c.id !== null);
    const newCategories = categories.value.filter(c => c.id === null);

    const payload = {
      event_id: eventId,
      change_allcategories: change_allcategories.value, // proměnná pro hromadné uložení kat. do etap
      updated_categories: updatedCategories,
      new_categories: newCategories,
      categories_to_delete: categoriesToDelete.value,
    };

    const response = await apiClient.put('categories/', payload);
    categoriesToDelete.value = []; // Reset seznamu pro smazání
    dialogCategory.value = false; // Zavřete dialog po úspěšné aktualizaci
    dialogCategoryRace.value = false; // Zavřete dialog po úspěšné aktualizaci
    dialogCategoryRace0.value = false; // Zavřete dialog po úspěšné aktualizaci
    // zobrazení hlášky o úspěchu
    errorMessage.value = "";
    if (response.data.not_deleted && response.data.not_deleted.length > 0) {
      successMessage.value = `Kategorie byly úspěšně aktualizovány, ale některé nebyly smazány kvůli existujícím přihláškám: ${response.data.not_deleted.join(', ')}`;
    } else {
      successMessage.value = "Kategorie byly úspěšně aktualizovány";
    }
    // načtení aktuálních kategorií
    await fetchCategories(currentRaceId.value);

  } catch (error) {
    console.error("Chyba při aktualizaci kategorií", error);
    // Kontrola na chybu 406 a nastavení errorMessage z odpovědi
    if (error.response && error.response.status === 406) {
      errorMessage.value = error.response.data.error || "Kategorie se nepodařilo uložit kvůli duplicitě.";
    } else {
      errorMessage.value = "Kategorie se nepodařilo uložit.";
    }

    successMessage.value = "";
  }
};


// uložení kategorií do proměnné pro smazání
const markCategoryForDeletion = (categoryId) => {
  if (!categoriesToDelete.value.includes(categoryId)) {
    categoriesToDelete.value.push(categoryId);
  }
  // Odstraňte kategorii z aktuálně zobrazených kategorií
  categories.value = categories.value.filter(category => category.id !== categoryId);
};


const fetchFiles = async (raceId) => {
  try {
    const response = await apiClient.get(`files/?race_id=${raceId}`);
    files.value = response.data; // Update the files array with the fetched data
  } catch (error) {
    console.error("Nepodařilo se načíst soubory", error);
  }
};

const editFile = (file) => {
  file.editing = true; // Přímo nastavíme vlastnost objektu
};

const updateFiles = async (file) => {
  try {
    const formData = new FormData();
    formData.append('id', file.id); // Přidáváme ID souboru pro jeho jednoznačnou identifikaci na serveru
    formData.append('name', file.name);
    formData.append('specific', file.specific);
    formData.append('url', file.url);

    if (file.newFile) { // Kontrola, zda byl přiložen nový soubor
      formData.append('location', file.newFile); 
    }

    // Přidání race_id pouze pokud je explicitně potřeba aktualizovat spojení souboru a závodu
    if (currentRaceId.value) {
      formData.append('race_id', currentRaceId.value);
    }

    if (file.newFile) { // Kontrola, zda byl přiložen nový soubor
      formData.append('location', file.newFile);  // Použití `location` místo `file`
    }


    await apiClient.put(`files/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    file.editing = false; // zrušení editace souboru
    await fetchFiles(currentRaceId.value); // Obnovení seznamu souborů
    successMessage.value = "Položka byla úspěšně aktualizována";
  } catch (error) {
    console.error("Nepodařilo se aktualizovat soubory", error);
  }
};


const fileError = ref('');  // Reaktivní reference pro chybové zprávy

const handleNewFileChange = (event, file = null) => {
  const selectedFile = event.target.files[0];  // Získání vybraného souboru z inputu
  if (selectedFile) {
    if (selectedFile.size > 10485760) {  // Kontrola velikosti souboru - 10 MB max
      fileError.value = 'Soubor nesmí být větší než 10 MB.';
    } else {
      fileError.value = '';  // Resetování chybové zprávy, pokud soubor splňuje podmínky
      
      // Pokud je `file` parametr specifikovaný, jedná se o úpravu existujícího souboru
      if (file) {
        file.newFile = selectedFile;  // Přidání nového souboru do existující položky
      } else {
        newFile.value.file = selectedFile;  // Jinak je to nový soubor v novém záznamu
      }
    }
  }
};


const addNewFile = async () => {
  if (!newFile.value.file || !newFile.value.name) {
    alert('Název a soubor jsou povinné.');
    return;
  }

  try {
    const formData = new FormData();
    formData.append('name', newFile.value.name);
    if (newFile.value.specific) formData.append('specific', newFile.value.specific);
    formData.append('location', newFile.value.file);
    formData.append('race_file', currentRaceId.value);

    await apiClient.post('files/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    successMessage.value = "Soubor byl úspěšně nahrán";
    showAddFileSection.value = false; // Close the add file section
    newFile.value = { name: '', specific: '', location: '' }; // Reset the new file object
    await fetchFiles(currentRaceId.value); // Načti soubory
  } catch (error) {
    console.error("Nepodařilo se nahrát soubor", error);
  }
};


const editLink = (file) => {
  editLink.value = { ...file };
  file.editing = true; // zobrazení editačního režimu
};

const deleteFile = async (fileId) => {
  try {
    await apiClient.delete(`files/?id=${fileId}`);
    await fetchFiles(currentRaceId.value); // Obnovení seznamu souborů
    successMessage.value = "Položka byla úspěšně smazána";
    dialogDeleteFile.value = false; // Close any confirmation dialog if open
  } catch (error) {
    console.error("Nepodařilo se smazat soubor", error);
  }
};


 // funkce přidání odkazu. Editace a smazání je pomocí file
const addNewLink = async () => {
  if (!newLink.value.name || !newLink.value.url) {
    alert('Název a URL odkazu jsou povinné.');
    return;
  }

  try {
    const formData = new FormData();
    formData.append('name', newLink.value.name);
    formData.append('url', newLink.value.url);
    formData.append('race_file', currentRaceId.value);
    formData.append('is_link', true); // Přidání, aby bylo jasné, že se jedná o odkaz, nepoužívá se

    await apiClient.post('files/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    // Zpracování úspěšného přidání
    successMessage.value = "Odkaz byl úspěšně přidán";
    showAddLinkSection.value = false; // Zavření sekce přidání odkazu
    newLink.value = { name: '', url: '' }; // Reset the new link object
    await fetchFiles(currentRaceId.value); // Znovu načtení seznamu souborů/odkazů
  } catch (error) {
    console.error("Nepodařilo se přidat odkaz", error);
  }
};


// Kopírování api key

const copied = ref(false)

function copyKey(race) {
  navigator.clipboard.writeText(race.race_api_key).then(() => {
    copied.value = true
    setTimeout(() => {
      copied.value = false
    }, 2000)
  })
}


// ****************** //
// ****************** //          *****************
// UBYTOVÁNÍ A STRAVA //        **********************
// ****************** //          *****************
// ****************** //



const days = ref([createNewDay()]);

// Vytvoření nového dne pro jídla
function addNewDay() {
  days.value.push(createNewDay());
}

const accommodations = ref([
  { id: null, name: '', price: '', limit: null, variant: 1, date_from: '', date_to: '', loadedFromAPI: false },
  { id: null, name: '', price: '', limit: null, variant: 2, date_from: '', date_to: '', loadedFromAPI: false },
  { id: null, name: '', price: '', limit: null, variant: 3, date_from: '', date_to: '', loadedFromAPI: false },
  { id: null, name: '', price: '', limit: null, variant: 4, date_from: '', date_to: '', loadedFromAPI: false }
]);

// Příprava ubytování před odesláním dat do API
function prepareAccommodationsData() {
  const preparedAccommodations = [];
  accommodations.value.forEach(acc => {
    // Předpokládáme, že acc.date_from a acc.date_to jsou správně nastaveny
    if (acc.name && (acc.price !== undefined && acc.price !== null) && acc.date_from && acc.date_to) {  // Zkontrolujte, zda jsou všechna potřebná data k dispozici
      preparedAccommodations.push({
        name: acc.name,
        variant: acc.variant,
        price: acc.price,
        id: acc.id,
        limit: acc.limit,
        date_from: acc.date_from, // Nejranější možné datum
        date_to: format(acc.date_to, 'yyyy-MM-dd'), // Nejpozdnější možné datum
        only_all_days: acc.only_all_days,
        type: "accommodation",
        loadedFromAPI: acc.loadedFromAPI,
      });
    }
  });
  return preparedAccommodations;
}

// dialog před smazání varianty ubytování
const dialogAccomodations = reactive(accommodations.value.map(() => false));
function showAccomodationDialog(index) {
  dialogAccomodations[index] = true;
}

const fieldErrors = reactive({});

function resetAccommodation(index) {
  const acc = accommodations.value[index];
  acc.name = '';
  acc.price = '';
  acc.limit = null;
  // Následující pole mohou být také resetována, nebo ponechána dle vašich potřeb
  acc.date_from = '';
  acc.date_to = '';
  acc.only_all_days = false;
  submitEntryService()
}

function validateDay(day) {
  let hasError = false;
  for (const meal of day.breakfasts.concat(day.lunches, day.dinners)) {
    if ((meal.name && !meal.price) || (!meal.name && meal.price)) {
      fieldErrors[meal.food_type + meal.variant] = 'Název i cena jsou povinné';
      hasError = true;
    } else {
      fieldErrors[meal.food_type + meal.variant] = '';
    }
  }

  if ((day.breakfasts.concat(day.lunches, day.dinners).some(meal => meal.name || meal.price)) && !day.date) {
    fieldErrors['date' + day.date] = 'Datum je povinné, pokud jsou vyplněny jídla';
    hasError = true;
  } else {
    fieldErrors['date' + day.date] = '';
  }

  return hasError;
}

function validateAccommodation() {
  let hasError = false;
  accommodations.value.forEach((acc, index) => {
    // Zkontrolujte, zda je některé z polí vyplněno
    const isAnyFieldFilled = acc.name || acc.price === 0 || acc.price || acc.date_from || acc.date_to;

    // Zkontrolujte, zda jsou všechna požadovaná pole vyplněna
    const areAllFieldsFilled = acc.name && (acc.price !== undefined && acc.price !== null) && acc.date_from && acc.date_to;

    if (isAnyFieldFilled && !areAllFieldsFilled) {
      // Nastaví chybovou zprávu, pokud je některé z polí vyplněno, ale ne všechna
      fieldErrors[`acc${index}`] = 'Název, cena a datumy jsou povinné';
      hasError = true;
    } else {
      // Vymazání jakýchkoliv dřívějších chyb, pokud jsou všechna data nyní správně
      fieldErrors[`acc${index}`] = '';
    }
  });
  return hasError;
}

// Vytvoření nového dne pro vytvoření jídel
function createNewDay() {
  return {
    date: '',
    breakfasts: [{ name: '', price: '', food_type: 'sn', variant: 1, type: 'food' }],
    lunches: [
      { name: '', price: '', food_type: 'ob', variant: 1, type: 'food' },
      { name: '', price: '', food_type: 'ob', variant: 2, type: 'food' },
      { name: '', price: '', food_type: 'ob', variant: 3, type: 'food' },
      { name: '', price: '', food_type: 'ob', variant: 4, type: 'food' }
    ],
    dinners: [
      { name: '', price: '', food_type: 've', variant: 1, type: 'food' },
      { name: '', price: '', food_type: 've', variant: 2, type: 'food' },
      { name: '', price: '', food_type: 've', variant: 3, type: 'food' },
      { name: '', price: '', food_type: 've', variant: 4, type: 'food' }
    ]
  };
}

// Načtení dat pro odeslání do api ve formátu date: lunces(), breakfasts(), dinners()
function prepareFoodData() {
  return days.value.map(day => ({
    date: day.date || null,
    breakfasts: day.breakfasts.map(b => ({ ...b, id: b.id || null, date: day.date || null })),
    lunches: day.lunches.map(l => ({ ...l, id: l.id || null, date: day.date || null })),
    dinners: day.dinners.map(d => ({ ...d, id: d.id || null, date: day.date || null }))
  }));
}

// odeslání dat EntryServices do API se zobrazením chyb před odesláním, pokud nejsou vyplněná pole
const submitEntryService = async () => {

  let hasErrors = false;
  // validace jídel
  days.value.forEach(day => {
    if (validateDay(day)) {
      hasErrors = true;
    }
  });

    // Validace ubytování
  if (validateAccommodation()) {
    hasErrors = true;
  }

  if (hasErrors) {
    console.error('Formulář obsahuje chyby a nemůže být odeslán.');
    return; // Ukončí funkci, pokud jsou chyby
  }

  const siRentData = si_rent.value.map(service => ({
    id: service.id,
    price: service.price,
  }));

  const payload = {
    accommodations: prepareAccommodationsData(),
    food: prepareFoodData(),
    si_rent: siRentData,
  };

  try {
    const eventId = route.params.id; // event ID z URL adresy
    const response = await apiClient.post(`race_service/?event_id=${eventId}`, payload);
    successMessage.value = "Služby byly úspěšně uloženy.";
    errorMessage.value = "";
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    console.log('Data successfully sent to the API:', response);
    fetchRaceServiceData()
  } catch (error) {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    if (error.response.status === 423) {
      successMessage.value = "";
      errorMessage.value = "Položky ubytování nebo jídla nemohou být kvůli přihlášeným závodníkům smazány nebo upraveny.";
    } else {
      errorMessage.value = "Jídlo a ubytování se nepodařilo uložit";
    }
  }
};

// Načtení Services pro event
async function fetchRaceServiceData() {
  const eventId = route.params.id;  // ID události z URL
  try {
    const response = await apiClient.get(`race_service/?event_id=${eventId}`);
    if (response.status === 200) {
      accommodations.value = processAccommodationData(response.data.accommodations);
      availableAccommodations.value = groupByDate(response.data.accommodations); // pro editaci entries
      if (response.data.food && Object.keys(response.data.food).length > 0) {
        days.value = processFoodData(response.data.food);
        availableFood.value = groupFoodByDate(response.data.food); // pro editaci entries


      } else {
        days.value = [createNewDay()]; // Vytvoří prázdný den, pokud nejsou načtena žádná data o jídle
        availableFood.value = {};
      }

      // Načtení si_rent dat
      si_rent.value = response.data.si_rent.map(service => ({
        id: service.id,
        price: service.price,
      }));
      
    } else {
      console.error('Failed to fetch data:', response.status);
    }
  } catch (error) {
    console.error('Error fetching Race Service data:', error);
  }
}


// Zpracování ubytování z API na frontend
function processAccommodationData(accommodationsData) {
  const processedAccommodations = { // defaultně 4 varianty ubytování
    1: { id: null, name: '', price: '', limit: null, variant: '1', date_from: '', date_to: '', only_all_days: false, loadedFromAPI: false },
    2: { id: null, name: '', price: '', limit: null, variant: '2', date_from: '', date_to: '', only_all_days: false, loadedFromAPI: false },
    3: { id: null, name: '', price: '', limit: null, variant: '3', date_from: '', date_to: '', only_all_days: false, loadedFromAPI: false },
    4: { id: null, name: '', price: '', limit: null, variant: '4', date_from: '', date_to: '', only_all_days: false, loadedFromAPI: false }
  };

  accommodationsData.forEach(acc => {
    const variant = acc.variant || '1';  // Přiřazení varianty, pokud není specifikováno, použijeme '1'
    let variantData = processedAccommodations[variant];

    // Agregace dat do jedné varianty ubytování, vytvoření date from a date to
    if (!variantData.date_from || new Date(acc.date) < new Date(variantData.date_from)) {
      variantData.date_from = acc.date;
    }
    if (!variantData.date_to || new Date(acc.date) > new Date(variantData.date_to)) {
      variantData.date_to = acc.date;
    }
    variantData.id = acc.id;
    variantData.name = acc.name;
    variantData.price = acc.price;
    variantData.limit = acc.limit || null;  // null kvůli integer field
    variantData.type = "accommodation",
    variantData.only_all_days = acc.only_all_days;
    variantData.loadedFromAPI = true;  // Označení, že tato varianta byla načtena z API a zakáže se manipulace s daty
  });

  return Object.values(processedAccommodations);  // Vrátí pole s vždy čtyřmi variantami ubytování
}

// zpracování jídla tak, aby se rozdělilo a zobrazilo na 3 sloupce a více variantách
function processFoodData(foodData) {
  const dayList = [];
  for (const [date, meals] of Object.entries(foodData)) {
    dayList.push({
      date,
      breakfasts: fillMealsArray(meals.breakfasts, 1, 'sn'), // výběr z jedné varianty snídaně
      lunches: fillMealsArray(meals.lunches, 4, 'ob'),
      dinners: fillMealsArray(meals.dinners, 4, 've')
    });
  }
  return dayList;
}

function fillMealsArray(meals, count, foodType) {
  let result = meals && meals.length > 0 ? meals : [];
  while (result.length < count) {
    result.push({ name: '', price: '', food_type: foodType, type: 'food', variant: result.length + 1 });
  }
  return result;
}



// ****************** //
// ****************** //          *****************
//      SLUŽBY        //        **********************
// ****************** //          *****************
// ****************** //

const si_rent = ref([{ id: null, price: null }]);






///////////////////////////////////
//////                     ////////                     ************
////// Správa přihlášených ////////                  *******************
//////                     ///////                      *************
///////////////////////////////////

 //////   Vytvoření Entry  //////


const dialogCreateEntryForm = ref(false);
const newEntry = ref({
  id: null,
  first_name: '',
  last_name: '',
  competitor_index: '',
  email: '',
  si_number: null,
  birth_year: null,
  competitor_gender: '',
  main_category_id: null,  // Kategorie pro hlavní soutěž
  race_categories: {},      // Kategorie pro jednotlivé etapy (races)
  race_services: [],         // Vybrané služby (ubytování, strava, atd.)
  start_preference: {},
  note: '',
  country: '',
  termin_prihl:''
});

// Načtení dostupných kategorií, závodů a služeb
const gender = ref([{title: 'Muž', value: 'M'}, {title: 'Žena', value: 'F'}]);
// const terminPrihlOptions = ref([{text: '1. termín', value: '1'}, {text: '2. termín', value: '2'}]);

const startGroups = ref([
  { text: 'První startující', value: '0' },
  { text: 'První skupina', value: '1' },
  { text: 'Druhá skupina', value: '2' },
  { text: 'Třetí skupina', value: '3' },
  { text: 'Poslední startující', value: '4' }
]);


// Načtení hlavní kategorie z prvního závodu (race0)
const mainCategories = computed(() => {
  return race0.value && race0.value.categories ? race0.value.categories : [];
});


// Funkce na změnu výběru stravy
function onFoodChange(date, mealType, value) {
  if (mealType === 'breakfast') {
    if (value === null || value === false) {
      // Pokud je checkbox odškrtnutý, smažeme záznam
      delete selectedServices.breakfasts[date];
    } else {
      // Pokud je checkbox zaškrtnutý, přidáme hodnotu
      selectedServices.breakfasts[date] = value;
    }
  } else if (mealType === 'lunch') {
    if (value === null || value === false) {
      delete selectedServices.lunches[date];
    } else {
      selectedServices.lunches[date] = value;
    }
  } else if (mealType === 'dinner') {
    if (value === null || value === false) {
      delete selectedServices.dinners[date];
    } else {
      selectedServices.dinners[date] = value;
    }
  }
}

// Dočasné úložiště pro výběry
const selectedServices = reactive({
  accommodations: {},  // { [date]: accommodationId }
  breakfasts: {},      // { [date]: breakfastId }
  lunches: {},         // { [date]: lunchId }
  dinners: {},         // { [date]: dinnerId }
  si_rent: false       // Půjčení SI čipu (true/false)
});


const eventId = route.params.id;

const filteredEntries = ref([]);
const isLoading = ref(false);  // Pro zobrazení stavu načítání
const selectedEntryId = ref(null);


// Funkce pro zpracování změny ve vstupu příjmení
const onlast_nameChange = async (searchText) => {
  if (searchText && searchText.trim().length >= 1) {
    try {
      isLoading.value = true;
      const response = await apiClient.get(`/event/${eventId}/entry/create/`, {
        params: { last_name: searchText.trim() }
      });
      filteredEntries.value = response.data; // Uložení výsledků z API
    } catch (error) {
      console.error('Chyba při volání API:', error);
    } finally {
      isLoading.value = false;
    }
  } else {
    filteredEntries.value = [];
  }
};

// Watcher pro sledování změny ve vybraném ID a vyplnění formuláře
watch(selectedEntryId, (newId) => {
  if (newId) {
    const selectedEntry = filteredEntries.value.find(entry => entry.id === newId);
    if (selectedEntry) {
      // Naplnění formuláře vybranými daty
      newEntry.value.first_name = selectedEntry.first_name;
      newEntry.value.last_name = selectedEntry.last_name; // Vložit pouze last_name
      newEntry.value.birth_year = selectedEntry.birth_year;
      newEntry.value.competitor_index = selectedEntry.competitor_index;
      newEntry.value.email = selectedEntry.email;
      newEntry.value.si_number = selectedEntry.si_number;
      newEntry.value.competitor_gender = selectedEntry.competitor_gender;
      newEntry.value.id = selectedEntry.id;
      newEntry.value.country = selectedEntry.country;

    }
  }
});

// Funkce pro získání zobrazení "last_name first_name" v autocomplete
const getFullName = (entry) => `${entry.last_name} ${entry.first_name} ${entry.competitor_index}`;


// Definice pravidel
const rules = {
  last_nameRules: [v => !!v || 'Příjmení je povinné'],
  firstNameRules: [v => !!v || 'Jméno je povinné'],
  mainCategoryRules: [v => !!v || 'Kategorie je povinná'],
  emailRules: [v => (!v || /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(v)) || 'Neplatný e-mail']
};

// Chybové zprávy pro pole
const errors = reactive({
  last_name: [],
  first_name: [],
  main_category_id: [],
  email: []
});

// Funkce pro validaci a odeslání formuláře
const validateForm = () => {
  // Validace jednotlivých polí a ukládání chybových zpráv
  errors.last_name = rules.last_nameRules.map(rule => rule(newEntry.value.last_name)).filter(error => error !== true);
  errors.first_name = rules.firstNameRules.map(rule => rule(newEntry.value.first_name)).filter(error => error !== true);
  errors.main_category_id = rules.mainCategoryRules.map(rule => rule(newEntry.value.main_category_id)).filter(error => error !== true);
  errors.email = rules.emailRules.map(rule => rule(newEntry.value.email)).filter(error => error !== true);

  // Formulář je platný, pokud nejsou žádné chyby
  return !errors.last_name.length && !errors.first_name.length && !errors.main_category_id.length && !errors.email.length;
};

// Sledování změn ve formuláři a automatické skrytí chybových zpráv
watch(newEntry, (newValues) => {
  errors.last_name = rules.last_nameRules.map(rule => rule(newValues.last_name)).filter(error => error !== true);
  errors.first_name = rules.firstNameRules.map(rule => rule(newValues.first_name)).filter(error => error !== true);
  errors.main_category_id = rules.mainCategoryRules.map(rule => rule(newValues.main_category_id)).filter(error => error !== true);
  errors.email = rules.emailRules.map(rule => rule(newValues.email)).filter(error => error !== true);
}, { deep: true });

const submitEntry = async () => {

  if (!validateForm()) {
    console.log('Formulář obsahuje chyby');
    return;
  }

  try {
    // Přiřazení vybraných služeb ze selectedServices do newEntry.race_service
    newEntry.value.race_services = [];

    // Přidání ubytování
    Object.values(selectedServices.accommodations).forEach(accommodationId => {
      if (accommodationId) {
        newEntry.value.race_services.push(accommodationId);
      }
    });

    // Přidání stravy
    Object.values(selectedServices.breakfasts).forEach(breakfastId => {
      if (breakfastId) {
        newEntry.value.race_services.push(breakfastId);
      }
    });
    Object.values(selectedServices.lunches).forEach(lunchId => {
      if (lunchId) {
        newEntry.value.race_services.push(lunchId);
      }
    });
    Object.values(selectedServices.dinners).forEach(dinnerId => {
      if (dinnerId) {
        newEntry.value.race_services.push(dinnerId);
      }
    });

    // Přidání SI čipu, pokud je zaškrtnutý
    if (selectedServices.si_rent && si_rent.value.length > 0) {
      newEntry.value.race_services.push(si_rent.value[0].id);
    }

     // Vytvoření payloadu pro přihlášku
    const entriesPayload = {
      races: [
        // Přidání hlavního závodu jako první položky do `races`
        {
          id: newEntry.value.id,
          race: race0.value.id, // Tady přidejte ID hlavního závodu
          competitor_club: newEntry.value.competitor_club,
          competitor_category: newEntry.value.main_category_id || null, // Hlavní kategorie
          first_name: newEntry.value.first_name,
          last_name: newEntry.value.last_name,
          competitor_index: newEntry.value.competitor_index,
          si_number: newEntry.value.si_number || null,
          termin_prihl: newEntry.value.termin_prihl || null,
          competitor_gender: newEntry.value.competitor_gender,
          birth_year: newEntry.value.birth_year || null,
          country: newEntry.value.country,
          start_preference: '', // Můžete přidat start group pro hlavní závod, pokud je potřeba
          note: newEntry.value.note || '',
          email: newEntry.value.email,
          race_services: newEntry.value.race_services,
        },
        // Přidání ostatních závodů
        ...races.value
          .filter(race => newEntry.value.race_categories[race.id]) // Pouze závody s vyplněnou kategorií
          .map(race => ({
            id: newEntry.value.id,
            race: race.id,
            competitor_club: newEntry.value.competitor_club,
            competitor_category: newEntry.value.race_categories[race.id] || null,
            first_name: newEntry.value.first_name,
            last_name: newEntry.value.last_name,
            competitor_index: newEntry.value.competitor_index,
            si_number: newEntry.value.si_number || null,
            termin_prihl: newEntry.value.termin_prihl || null,
            competitor_gender: newEntry.value.competitor_gender,
            birth_year: newEntry.value.birth_year || null,
            country: newEntry.value.country,
            start_preference: newEntry.value.start_preference[race.id] || '',
            note: newEntry.value.note || '',
            email: newEntry.value.email,
          })),
      ],
    };


    // Odeslání na backend jako jeden požadavek s races a race_service
    await apiClient.post(`event/${eventId}/entry/create/`, entriesPayload);

    // Uzavření dialogového okna po úspěšném odeslání
    dialogCreateEntryForm.value = false;
    successMessage.value = 'Přihláška byla úspěšně založena';
    errorMessage.value = '';
    // Resetování hodnot ve formuláři po úspěšném odeslání
    resetEntryForm();
    fetchEntries()
    fetchRaceServiceData();


    


  } catch (error) {

    console.error('Chyba při odesílání přihlášek:', error);

    if (error.response && (error.response.status === 423 || error.response.status === 409)) {
      // Kontrola, jestli error.response.data obsahuje error zprávu a nastavení errorMessage
      errorMessage.value = error.response.data.error || error.response.data.message || error.response.data || 'Nastala chyba při odesílání dat.';
    } else {
      errorMessage.value = 'Nastala chyba při odesílání přihlášky. Zkuste to znovu později.';
    }
  }
};


// Funkce na resetování formuláře
function resetEntryForm() {
  // Resetování hodnot ve newEntry
  newEntry.value = {
    id: null, 
    first_name: '',
    last_name: '',
    competitor_index: '',
    email: '',
    si_number: null,
    birth_year: null,
    competitor_gender: '',
    main_category_id: null,
    race_categories: {},
    race_services: [],
    start_preference: {},
    note: '',
    country: '',
    termin_prihl: '',
  };

    // Resetování chybových zpráv
    Object.keys(errors).forEach(key => {
    errors[key] = [];
  });

  // Resetování hodnot v selectedServices
  selectedServices.accommodations = {};
  selectedServices.breakfasts = {};
  selectedServices.lunches = {};
  selectedServices.dinners = {};
  selectedServices.si_rent = false;

  // Resetování vyhledávacího pole
  selectedEntryId.value = null;
}

const swichCreateEntryForm = ref(true); // Přepínač mezi zobrazeními
const registeredUsersForm = ref(true); // Určuje, které zobrazení je aktivní

// Watcher pro přepínač `swichCreateEntryForm` - resetování formuláře po přepnutí
watch(swichCreateEntryForm, (newValue) => {
  if (newValue === true) {
    registeredUsersForm.value = true;
  } else {
    registeredUsersForm.value = false;
  }
  resetEntryForm(); // Resetovat formulář při každém přepnutí
});


 //////   Konec vytvoření entry    ///////







const entries = ref([
  {
    id: '',
    last_name: '',
    first_name: '',
    si_number: null,
    category: '',
    note: ''
    },
  // Přidejte další položky podle potřeby
]);

const emails = ref([]);
const showSnackbar = ref(false);



// přiřazení ubytování uživatele k dostupným ubytováním v soutěži
function initializeAccommodationData() {
  if (!selectedEntry.value.accommodation) {
    selectedEntry.value.accommodation = {};
  }
  Object.keys(availableAccommodations.value).forEach(date => {
    // Přiřazení již vybraných ubytování na základě ID z `selectedEntry.race_service`
    const selectedAccommodations = availableAccommodations.value[date].filter(acc => selectedEntry.value.race_service.includes(acc.id));
    selectedEntry.value.accommodation[date] = selectedAccommodations.map(acc => acc.id);
  });
}

// přiřřazení stravy uživatele ke stravě, která je v soutěži k dispozici
function initializeFoodData() {
  if (!selectedEntry.value.food) {
    selectedEntry.value.food = {};
  }
  Object.keys(availableFood.value).forEach(date => {
    if (!selectedEntry.value.food[date]) {
      selectedEntry.value.food[date] = { breakfasts: [], lunches: [], dinners: [] };
    }
    const selectedBreakfasts = availableFood.value[date].breakfasts.filter(food => selectedEntry.value.race_service.includes(food.id));
    const selectedLunches = availableFood.value[date].lunches.filter(food => selectedEntry.value.race_service.includes(food.id));
    const selectedDinners = availableFood.value[date].dinners.filter(food => selectedEntry.value.race_service.includes(food.id));

    selectedEntry.value.food[date].breakfasts = selectedBreakfasts.map(food => food.id);
    selectedEntry.value.food[date].lunches = selectedLunches.map(food => food.id);
    selectedEntry.value.food[date].dinners = selectedDinners.map(food => food.id);
  });
}


const allRaces = ref([]);

const getEntryRaceData = (entry, raceId) => {
  let raceData = entry.entry_race_data.find(data => data.race_id === raceId);
  if (!raceData) {
    raceData = { race_id: raceId, category_id: null, si_number: null };
    entry.entry_race_data.push(raceData);
  }
  return raceData;
};




const dialogEditEntry = ref(false);
const openEditDialog = (entry) => {
  selectedEntry.value = { ...entry, entry_race_data: entry.entry_race_data.map(data => ({ ...data })) };
  initializeAccommodationData();
  initializeFoodData();
  dialogEditEntry.value = true;
};

const dialogDeleteEntry = ref(false);
const openDeleteDialog = (entry) => {
  selectedEntry.value = entry;

  dialogDeleteEntry.value = true;
};



const sortByEntries = [{ key: 'last_name', order: 'asc' }]; // Klíč pro řazení

const selectedEntry = ref(null);

const entryHeaders = ref([
  { title: 'Příjmení', value: 'last_name', sortable: true},
  { title: 'Jméno', value: 'first_name', sortable: true },
  { title: 'Index', value: 'competitor_index', sortable: true },
  { title: 'Číslo čipu', value: 'si_number', sortable: true },
  { title: 'Kategorie', value: 'category_name', sortable: true },
  { title: 'Termín', value: 'termin_prihl', sortable: true, width: '20px' },
  { title: 'Čas přihlášení', value: 'timestamp', sortable: true, width: '180px' },
  { title: 'Poznámka', value: 'note', sortable: true, width: '200px' },
  { title: 'Akce', value: 'actions', sortable: false, width: '100px', align: 'center' }
]);




const availableAccommodations = ref([]);

// Funkce pro seskupení ubytování podle dat
function groupByDate(accommodations) {
  const grouped = {};
  accommodations.forEach(accommodation => {
    if (!grouped[accommodation.date]) {
      grouped[accommodation.date] = [];
    }
    grouped[accommodation.date].push(accommodation);
  });
  return grouped;
}

const availableFood = ref({});

function groupFoodByDate(foodItems) {
  return Object.entries(foodItems).reduce((grouped, [date, meals]) => {
    grouped[date] = {
      breakfasts: meals.breakfasts?.filter(food => food.price !== "") || [],
      lunches: meals.lunches?.filter(food => food.price !== "") || [],
      dinners: meals.dinners?.filter(food => food.price !== "") || []
    };
    return grouped;
  }, {});
}

// Funkce pro resetování vyhledávání
const resetSearch = () => {
  searchSurname.value = '';
  fetchEntries();
};

const searchSurname = ref('');
const isLoadingEntries = ref(true);


const fetchEntries = async () => {
  const eventId = route.params.id;

  try {
    let query = `entries/?event_id=${eventId}`;
    if (searchSurname.value) {
      query += `&last_name=${searchSurname.value}`;
    }
    const response = await apiClient.get(query);
    emails.value = response.data.map(entry => entry.email);
    entries.value = response.data.map(entry => {
      allRaces.value.forEach(race => {
        if (!entry.entry_race_data.some(data => data.race_id === race.id)) {
          entry.entry_race_data.push({
            race_id: race.id,
            category_id: null,
            category_name: 'nepřihlášen',
            si_number: null
          });
        }
      });
      entry.entry_race_data.sort((a, b) => {
        if (a.category_name === 'nepřihlášen') return -1;
        if (b.category_name === 'nepřihlášen') return 1;
        return a.category_name.localeCompare(b.category_name);
      });
      return entry;
    });

  } catch (error) {
    console.error("Error fetching entries:", error);
  } finally {
    isLoadingEntries.value = false;  // Loading skončí po úspěšném načtení nebo při chybě
  }
};


// Počáteční stav si_rent checkboxu
const siRentChecked = computed({
  get() {
    return selectedEntry.value && si_rent.value[0].id && selectedEntry.value.race_service.includes(si_rent.value[0].id);
  },
  set(value) {
    if (value) {
      // Přidá si_rent ID do race_service, pokud je checkbox zaškrtnutý
      if (!selectedEntry.value.race_service.includes(si_rent.value[0].id)) {
        selectedEntry.value.race_service.push(si_rent.value[0].id);
      }
    } else {
      // Odstraní si_rent ID z race_service, pokud není checkbox zaškrtnutý
      selectedEntry.value.race_service = selectedEntry.value.race_service.filter(id => id !== si_rent.value[0].id);
    }
  }
});


// uložení emailů do schránky počítače
const saveEmailsToClipboard = async () => {
  await fetchEntries(); // Fetch the entries first

  // Filtrovat emaily, které neobsahují 'rob-is.cz' a nejsou prázdné
  const filteredEmails = emails.value.filter(email => email && !email.includes('rob-is.cz'));

  const emailString = filteredEmails.join(', ');
  navigator.clipboard.writeText(emailString).then(() => {
    console.log('Emails copied to clipboard:', emailString);
    showSnackbar.value = true; // Show snackbar alert
  }).catch(err => {
    console.error('Failed to copy emails to clipboard:', err);
  });
};

const editEntry = async () => {
  try {
    // Filtrujeme pouze ty závodní data, které mají vyplněnou kategorii
    selectedEntry.value.entry_race_data = selectedEntry.value.entry_race_data.filter(data => data.category_id !== null);

     // Sestavení seznamu ID ubytování z objektu accommodation pro každý den
    const accommodationIds = Object.values(selectedEntry.value.accommodation).flat().filter(id => id !== null);

    // Sestavení seznamu ID stravy z objektu food pro každý den
    const foodIds = Object.values(selectedEntry.value.food).flatMap(day =>
      ['breakfasts', 'lunches', 'dinners'].flatMap(mealType => day[mealType] || [])
    ).filter(id => id !== null);

    // Kombinace ID ubytování, stravy a si_rent do race_service
    const raceServiceIds = [...accommodationIds, ...foodIds];

    // Přidání půjčení čipu do raceservices_id, pokud je to vybráno
    if (siRentChecked.value) {
      raceServiceIds.push(si_rent.value[0].id);
    }

    // Příprava payloadu pro odeslání na server
    const payload = {
      ...selectedEntry.value,
      race_service: raceServiceIds  // Přidání aktualizovaného seznamu race_service
    };

    await apiClient.put(`entries/?entry_id=${selectedEntry.value.id}`, payload);
    fetchEntries();
    fetchRaceServiceData();

    successMessage.value = "Přihláška byla úspěšně aktualizována.";
    errorMessage.value = "";
    dialogEditEntry.value = false;
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
  } catch (error) {
    console.error("There was an error updating the entry:", error);
    if (error.response && error.response.status === 423) {
      errorMessage.value = "Limit pro tuto variantu ubytování byl překročen.";
    } else {
      errorMessage.value = "Nepodařilo se aktualizovat přihlášku.";
    }
    successMessage.value = "";
    dialogEditEntry.value = true;

  }
};


const deleteEntry = async () => {
  try {
    await apiClient.delete(`entries/?entry_id=${selectedEntry.value.id}`);
    fetchEntries();
    successMessage.value = "Přihláška byla úspěšně smazána.";
    dialogDeleteEntry.value = false;
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
  } catch (error) {
    console.error("There was an error deleting the entry:", error);
    errorMessage.value = "Nepodařilo se smazat přihlášku.";
    dialogDeleteEntry.value = false;
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy

  }
};

const accommodationVariants = computed(() => {
  const variants = {};
  Object.values(availableAccommodations.value).forEach(dateAccommodations => {
    dateAccommodations.forEach(accommodation => {
      if (!variants[accommodation.variant]) {
        variants[accommodation.variant] = {
          variant: accommodation.variant,
          count: accommodation.count,
          limit: accommodation.limit
        };
      }
    });
  });
  return Object.values(variants).filter(variant => variant.count > 0);
});



const loadingSubmit = ref(false); //logika pro ukázání načítání po odeslání nebo nahrání results / startlist

///////////////////////////////////
//////                     ////////                     ************
//////   Správa startovek  ////////                  *******************
//////                     ///////                      *************
///////////////////////////////////

const dialogAddStartlist = ref(false);
const uploadedStartlistFile = ref(null);
const errorStartlistMessage = ref('');
const errorStartlistSendMessage = ref('');

const isFileValid = ref(false);
const successStartlistMessage = ref('');
const stepStart = ref(1);
const totalStartSteps = 2;

const StartlistSortBy = [{key: 'category_name', order: 'asc'}]; // Klíč pro řazení

const startlist = ref([]);
const isLoadingStartlist = ref(true);


const GetStartlistHeaders = ref([

      { title: 'Příjmení', value: 'last_name', sortable: true },
      { title: 'Jméno', value: 'first_name', sortable: true },
      { title: 'Čas startu', value: 'competitor_start_time', sortable: true },
      { title: 'Kat.', value: 'category_name', sortable: true },
      { title: 'Index', value: 'competitor_index', sortable: true },
      { title: 'Číslo čipu', value: 'si_number', sortable: true },
      { title: 'Oddíl', value: 'club_shortcut', sortable: true },
      { title: 'Akce', value: 'actions', width: '100px', align: 'center', sortable: false },

    ]);

const fetchStartlist = async () => {
    isLoadingStartlist.value = true;
    try {
      const response = await apiClient.get(`/startlist/?race_id=${currentRaceId.value}`);
      startlist.value = response.data;
    } catch (error) {
      console.error('Chyba při načítání dat:', error);
    } finally {
      isLoadingStartlist.value = false;  // Loading skončí po úspěšném načtení nebo při chybě
  }


  };



const startlistHeaders = [
  { title: 'St. č.', value: 'start_number', sortable: true, width: '90px', align: 'center' },
  { title: 'Příjmení', value: 'last_name', sortable: true },
  { title: 'Jméno', value: 'first_name', sortable: true },
  { title: 'Start relativní', value: 'start_time_relative', sortable: true },
  { title: 'Čas reálný', value: 'start_time_real', sortable: true },
  { title: 'Index', value: 'competitor_index', align: 'center', sortable: true },
  { title: 'Kat.', value: 'category_name', sortable: true },
  { title: 'Čip', value: 'si_number', sortable: true },
  { title: 'Akce', value: 'actions', width: '100px', align: 'center', sortable: false },
];

function openStartlistStepperDialog() {
  dialogAddStartlist.value = true;
}


const handleFileUpload = (event) => {
  errorStartlistMessage.value = '';
  successStartlistMessage.value = '';
  const file = event.target.files[0];

  if (file && file.name && file.name.endsWith('.csv')) {
    uploadedStartlistFile.value = file;
    isFileValid.value = true;
  } else {
    uploadedStartlistFile.value = null;
    isFileValid.value = false;
    errorStartlistMessage.value = 'Vyberte prosím platný soubor ve formátu .csv';
  }
};


const startlistEntryOk = ref([]);
const startlistEntryCreate = ref([]);
const startlistEntryEmpty = ref([]);
const combinedStarts = ref([]);

const updateCombinedStarts = () => {
  combinedStarts.value = [
    ...startlistEntryOk.value,
    ...startlistEntryCreate.value,
    ...startlistEntryEmpty.value,
  ];
  console.log('Updated combinedStarts:', combinedStarts.value); // Debugging
};

const selectedStart = ref(null);
const dialogEditStart = ref(false);
const dialogDeleteStart = ref(false);

// převod mm:ss na hh:mm:ss aby se to zobrazilo v input field type=time
const convertToTimeFormat = (timeString) => {
  if (!timeString) return '';

  const parts = timeString.split(':');

  if (parts.length === 1) {
    // Formát je jen minuty (např. 125)
    const minutes = parseInt(parts[0], 10);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;
  } else if (parts.length === 2) {
    // Formát je MM:SS (např. 125:30)
    const minutes = parseInt(parts[0], 10);
    const seconds = parseInt(parts[1], 10);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }

  return timeString; // Pokud je už ve správném formátu HH:MM:SS
};

// převod hh:mm:ss na mm:ss
const convertToApiFormat = (timeString) => {
  if (!timeString) return '';

  const parts = timeString.split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const seconds = parts.length === 3 ? parseInt(parts[2], 10) : 0;

  // Pokud jsou hodiny přítomné, převedeme na celkové minuty
  const totalMinutes = hours * 60 + minutes;

  if (seconds > 0) {
    return `${totalMinutes}:${String(seconds).padStart(2, '0')}`;
  } else {
    return String(totalMinutes);
  }
};


// Funkce pro formátování času do HH:MM:SS
const formatTime = (timeString) => {
  if (!timeString) return ''; // Pokud není čas k dispozici, vraťte prázdný řetězec

  // Rozdělení časového řetězce na hodiny, minuty a sekundy
  const [hours, minutes, seconds] = timeString.split(':');

  // Ujistěte se, že jsou hodiny a minuty ve správném formátu (dvouciferné)
  const formattedTime = [
    hours.padStart(2, '0'),
    minutes.padStart(2, '0'),
    seconds ? seconds.padStart(2, '0') : '00'  // Zajistí, že sekundy budou také dvouciferné nebo doplněné nulou
  ].join(':');

  return formattedTime; // Vraťte čas ve formátu HH:MM:SS
};

const openEditStartDialog = (start) => {
  selectedStart.value = {
    ...start,
    start_time_real: formatTime(start.start_time_real),
    start_time_relative: convertToTimeFormat(start.start_time_relative)
  };
  dialogEditStart.value = true;
};


const saveEditStart = () => {

  // převod naformátovaných dat na mm:ss do api
  const formattedRelativeTime = convertToApiFormat(selectedStart.value.start_time_relative);
  // Nastavení formátovaných hodnot
  selectedStart.value.start_time_relative = formattedRelativeTime;

  let startIndex = -1;

  // Vyhledání podle indexu, pokud existuje
  if (selectedStart.value.competitor_index) {
    startIndex = combinedStarts.value.findIndex(
      (item) => item.competitor_index === selectedStart.value.competitor_index
    );
  }

  // Pokud index neexistuje nebo nebyl nalezen, vyhledání podle kombinace jména a příjmení
  if (startIndex === -1) {
    startIndex = combinedStarts.value.findIndex(
      (item) =>
        item.first_name.toLowerCase() === selectedStart.value.first_name.toLowerCase() &&
        item.last_name.toLowerCase() === selectedStart.value.last_name.toLowerCase()
    );
  }

  // Pokud byl záznam nalezen, aktualizujeme data
  if (startIndex !== -1) {
    combinedStarts.value[startIndex] = { ...selectedStart.value };

    // Aktualizace jednotlivých seznamů
    startlistEntryOk.value = combinedStarts.value.filter((item) =>
      startlistEntryOk.value.some(
        (okItem) => okItem.competitor_index === item.competitor_index ||
        (okItem.first_name.toLowerCase() === item.first_name.toLowerCase() &&
         okItem.last_name.toLowerCase() === item.last_name.toLowerCase())
      )
    );

    startlistEntryCreate.value = combinedStarts.value.filter((item) =>
      startlistEntryCreate.value.some(
        (createItem) => createItem.competitor_index === item.competitor_index ||
        (createItem.first_name.toLowerCase() === item.first_name.toLowerCase() &&
         createItem.last_name.toLowerCase() === item.last_name.toLowerCase())
      )
    );

    startlistEntryEmpty.value = combinedStarts.value.filter((item) =>
      startlistEntryEmpty.value.some(
        (emptyItem) => emptyItem.competitor_index === item.competitor_index ||
        (emptyItem.first_name.toLowerCase() === item.first_name.toLowerCase() &&
         emptyItem.last_name.toLowerCase() === item.last_name.toLowerCase())
      )
    );
  }

  // Zavření dialogu po uložení
  dialogEditStart.value = false;
};


const openDeleteStartDialog = (start) => {
  selectedStart.value = start;
  dialogDeleteStart.value = true;
};

const deleteStart = () => {
  let startIndex = combinedStarts.value.findIndex(
  (item) => item.competitor_index === selectedStart.value.competitor_index
);
  // Pokud nenalezen podle `competitor_index`, hledáme podle jména a příjmení
  if (startIndex === -1) {
    startIndex = combinedStarts.value.findIndex(
      (item) =>
        item.first_name.toLowerCase() === selectedStart.value.first_name.toLowerCase() &&
        item.last_name.toLowerCase() === selectedStart.value.last_name.toLowerCase()
    );
  }
  // Smazání, pokud byl záznam nalezen
  if (startIndex !== -1) {
    combinedStarts.value.splice(startIndex, 1);
    startlistEntryOk.value = combinedStarts.value.filter(
      (item) => startlistEntryOk.value.some(okItem => okItem.competitor_index === item.competitor_index)
    );
    startlistEntryCreate.value = combinedStarts.value.filter(
      (item) => startlistEntryCreate.value.some(createItem => createItem.competitor_index === item.competitor_index)
    );
    startlistEntryEmpty.value = combinedStarts.value.filter(
      (item) => startlistEntryEmpty.value.some(emptyItem => emptyItem.competitor_index === item.competitor_index)
    );
  }
  dialogDeleteStart.value = false;
};



// Funkce pro nahrání souboru a zpracování do response k editaci
const uploadStartlistFile = async () => {
  if (uploadedStartlistFile.value) {
    const formData = new FormData();
    formData.append('file', uploadedStartlistFile.value);

    try {
      const response = await apiClient.post(`startlist/?race_id=${currentRaceId.value}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      successStartlistMessage.value = 'Soubor byl úspěšně nahrán';
      errorStartlistMessage.value = '';

      // Zpracování dat z response
      startlistEntryOk.value = response.data.startlist_entry_ok;
      startlistEntryCreate.value = response.data.startlist_entry_create;
      startlistEntryEmpty.value = response.data.startlist_entry_empty;

      updateCombinedStarts(); // Aktualizace tabulky po načtení dat

    } catch (error) {
      console.error('Chyba při nahrávání souboru:', error);

      // Ošetření chyby 406 a dalších specifických chybových zpráv
      if (error.response && error.response.status === 406) {
        errorStartlistMessage.value = `Chyba: ${error.response.data.error || 'Neplatná data v souboru.'}`;
      } else {
        errorStartlistMessage.value = 'Chyba při nahrávání souboru na server.';
      }

      successStartlistMessage.value = '';
    }
  }
};

// Objekt pro uložení barev přiřazených řádkům při jejich inicializaci
const rowColors = ref({});

const setRowProps = ({ item }) => {
  const itemIndex = item.competitor_index || ''; // Získání hodnoty indexu
  const itemName = `${item.last_name.toLowerCase()} ${item.first_name.toLowerCase()}`; // Kombinace příjmení a jména

  // Pokud již existuje barva pro daný řádek, vraťte ji
  if (rowColors.value[itemIndex] || rowColors.value[itemName]) {
    return { class: rowColors.value[itemIndex] || rowColors.value[itemName] };
  }

  console.log('Checking row props for:', itemIndex, itemName);

  let rowClass = '';

  if (itemIndex) {
    // Pokud je přítomný index, kontrolujeme podle indexu
    if (startlistEntryOk.value.some(okItem => okItem.competitor_index === itemIndex)) {
      rowClass = 'bg-ok';
    } else if (startlistEntryCreate.value.some(createItem => createItem.competitor_index === itemIndex)) {
      rowClass = 'bg-create';
    } else if (startlistEntryEmpty.value.some(emptyItem => emptyItem.competitor_index === itemIndex)) {
      rowClass = 'bg-empty';
    }
    rowColors.value[itemIndex] = rowClass; // Uložení barvy pro tento řádek podle indexu
  } else {
    // Pokud index není přítomný, kontrolujeme podle kombinace příjmení a jména
    if (startlistEntryOk.value.some(okItem => `${okItem.last_name.toLowerCase()} ${okItem.first_name.toLowerCase()}` === itemName)) {
      rowClass = 'bg-ok';
    } else if (startlistEntryCreate.value.some(createItem => `${createItem.last_name.toLowerCase()} ${createItem.first_name.toLowerCase()}` === itemName)) {
      rowClass = 'bg-create';
    } else if (startlistEntryEmpty.value.some(emptyItem => `${emptyItem.last_name.toLowerCase()} ${emptyItem.first_name.toLowerCase()}` === itemName)) {
      rowClass = 'bg-empty';
    }
    rowColors.value[itemName] = rowClass; // Uložení barvy pro tento řádek podle kombinace příjmení a jména
  }

  return { class: rowClass };
};


const closeStartlistDialog = () => {
  dialogAddStartlist.value = false;
  errorStartlistMessage.value = '';
  errorStartlistSendMessage.value = '';
};

// odeslání JSON pro uložení startů
const saveStartData = async () => {
  try {
    await apiClient.post(
      `startlist/?race_id=${currentRaceId.value}&valid=True`,
      JSON.stringify(combinedStarts.value),  // Převod dat na JSON formát
      {
        headers: {
          'Content-Type': 'application/json',  // Nastavení správné hlavičky
        },
      }
    );
    successMessage.value = 'Data o startech úspěšně uložena';
    errorStartlistSendMessage.value = ""
    dialogAddStartlist.value = false;  // Zavřít dialog po úspěšném odeslání
    combinedStarts.value = [];  // Vynulovat obsah combinedStarts po úspěšném uložení
    rowColors.value = {}; // Resetování barev při úspěšném odeslání
    successStartlistMessage.value = '';
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy

    fetchEntries();
    fetchStartlist();

  } catch (error) {
    console.error('Chyba při odesílání dat:', error);

    // Kontrola kódu 406 a zobrazení specifické chyby
    if (error.response && error.response.status === 406 && error.response.data && Array.isArray(error.response.data.error)) {
      errorStartlistSendMessage.value = error.response.data.error; // Přiřadit celé pole chybových zpráv
      console.log('Error message:', errorStartlistSendMessage.value); // Debugging
    } else {
      errorStartlistSendMessage.value = ['Data pro startovku se nepodařilo odeslat.']; // Přiřadit jako pole
      console.log('Error message:', errorStartlistSendMessage.value);
    }
  }
};


const dialogDeleteAllStartTimes = ref(false);

// Funkce pro otevření dialogu pro smazání všech start_time
const openDeleteAllStartTimesDialog = () => {
  dialogDeleteAllStartTimes.value = true;
};

// Funkce pro mazání všech start_time
const deleteAllStartTimes = async () => {
  try {
    await apiClient.delete(`/startlist/?race_id=${currentRaceId.value}`);
    dialogDeleteAllStartTimes.value = false;
    successMessage.value = 'Všechny startovní časy byly úspěšně smazány.';
    fetchStartlist(); // Obnovit startovní listinu po smazání
  } catch (error) {
    console.error('Chyba při mazání startovních časů:', error);
    errorMessage.value = 'Nepodařilo se smazat startovní časy.';
  }
};


// Funkce pro otevření dialogu pro mazání jednoho startu
const openDeleteGetStartDialog = (start) => {
  selectedStart.value = start;
  dialogDeleteStart.value = true;
};


// Funkce pro mazání jednoho start_time podle competitor_id
const deleteGetStart = async () => {
  try {
    await apiClient.delete(`/startlist/?race_id=${currentRaceId.value}&competitor_id=${selectedStart.value.competitor}`);
    dialogDeleteStart.value = false;
    successMessage.value = 'Startovní čas byl úspěšně smazán.';
    fetchStartlist(); // Obnovit startovní listinu po smazání
  } catch (error) {
    console.error('Chyba při mazání startovního času:', error);
    errorMessage.value = 'Nepodařilo se smazat startovní čas.';
    dialogDeleteStart.value = false;
  }
};






///////////////////////////////////
//////                     ////////                     ************
//////   Správa výsledků   ////////                  *******************
//////                     ///////                      *************
///////////////////////////////////


const dialogEditResult = ref(false);

const successResultsMessage = ref('');
const stepResults = ref(1);
const totalResultsSteps = 3;
const selectedResult = ref(null);
const dialogDeleteResult = ref(false);

// Funkce pro otevření dialogu pro mazání jednoho výsledku v seznamu na stránce 
const openDeleteGetResultDialog = (start) => {
  selectedResult.value = start;
  dialogDeleteResult.value = true;
};


// Funkce pro mazání jednoho start_time podle competitor_id
const deleteGetResult = async () => {
  try {
    await apiClient.delete(`/results/?race_id=${currentRaceId.value}&competitor_id=${selectedResult.value.competitor_id}`);
    dialogDeleteResult.value = false;
    successMessage.value = 'Výsledek závodníka byl úspěšně smazán.';
    fetchResults(); // Obnovit startovní listinu po smazání
  } catch (error) {
    console.error('Chyba při mazání výsledku:', error);
    errorMessage.value = 'Nepodařilo se smazat výsledek závodníka.';
    dialogDeleteResult.value = false;
  }
};


const GetResultsHeaders = ref([
  { title: 'Poř.', value: 'place', width: '60px', align: 'center', sortable: true },
  { title: 'Příjmení', value: 'last_name', sortable: true },
  { title: 'Jméno', value: 'first_name', sortable: true },
  { title: 'Index', value: 'competitor_index', sortable: true },
  { title: 'Kat.', value: 'competitor_category_name', sortable: true },
  { title: 'Čas běhu', value: 'run_time', sortable: true },
  { title: 'Status', value: 'result_status', sortable: true },
  { title: 'Kontrol', value: 'punch_count', width: '60px', sortable: true },
  { title: 'Akce', value: 'actions', width: '100px', align: 'center', sortable: false },

]);

const ResultsSortBy = [{key: 'competitor_category_name', order: 'asc'}]; // Klíč pro řazení

const resultsHeaders = [
  { title: 'Poř.', value: 'place', width: '60px', align: 'center', sortable: true },
  { title: 'Příjmení', value: 'last_name', sortable: true },
  { title: 'Jméno', value: 'first_name', sortable: true },
  { title: 'Kat.', value: 'competitor_category_name', sortable: true },
  { title: 'Index', value: 'competitor_index', align: 'center', sortable: true },
  { title: 'Čas', value: 'run_time', sortable: true },
  { title: 'Akce', value: 'actions', width: '100px', align: 'center', sortable: false },
];

const dialogAddResults = ref(false);


const errorResultsMessage = ref('');
const errorResultsSendMessage = ref('');

const closeResultsDialog = () => {
  dialogAddResults.value = false;
  errorResultsMessage.value = '';
  errorResultsSendMessage.value = '';
};



const isLoadingResults = ref(true);
const results = ref([]);
const fetchResults = async () => {
    isLoadingResults.value = true;
    try {
      const response = await apiClient.get(`/results/?race_id=${currentRaceId.value}`);
      results.value = response.data.results;
    } catch (error) {
      console.error('Chyba při načítání dat:', error);
    } finally {
      isLoadingResults.value = false;  // Loading skončí po úspěšném načtení nebo při chybě
  }


  };

function openResultsStepperDialog() {
  dialogAddResults.value = true;
}


const resultsEntryOk = ref([]);
const resultsEntryCreate = ref([]);
const resultsEntryEmpty = ref([]);
const combinedResults = ref([]);
const uploadedResultsFile = ref(null);
const selectedFileType = ref(null);
const categoryProperties = ref([]);

const updateCombinedResults = () => {
  combinedResults.value = [
    ...resultsEntryOk.value,
    ...resultsEntryCreate.value,
    ...resultsEntryEmpty.value,
  ];
};


// Funkce pro nahrání souboru a zpracování do response k editaci
const uploadResultsFile = async () => {
  loadingSubmit.value = true;
  if (uploadedResultsFile.value && selectedFileType.value) {
    const formData = new FormData();
    formData.append('file', uploadedResultsFile.value);

    try {
      const response = await apiClient.post(`results/?race_id=${currentRaceId.value}&name=${selectedFileType.value}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      successResultsMessage.value = 'Soubor byl úspěšně nahrán';
      errorResultsMessage.value = '';

      // Zpracování dat z response
      resultsEntryOk.value = response.data.results_entry_ok;
      resultsEntryCreate.value = response.data.results_entry_create;
      resultsEntryEmpty.value = response.data.results_entry_empty;

      // Definuj category_properties z response
      categoryProperties.value = response.data.category_properties.map((category) => ({
        category_name: category.category_name,
        category_control_points: Array.isArray(category.category_control_points)
        ? category.category_control_points.join(', ')
        : category.category_control_points, // Zkontroluje, jestli je to pole
    })).sort((a, b) => a.category_name.localeCompare(b.category_name));


      updateCombinedResults(); // Aktualizace tabulky po načtení dat

    } catch (error) {
      console.error('Chyba při nahrávání souboru:', error);

      // Ošetření chyby 406 a dalších specifických chybových zpráv
      if (error.response && error.response.status === 406) {
        errorResultsMessage.value = `Chyba: ${error.response.data.error || 'Neplatná data v souboru.'}`;
      } else {
        errorResultsMessage.value = 'Chyba při nahrávání souboru na server.';
      }

      successResultsMessage.value = '';
    } finally {
      loadingSubmit.value = false;
    }
  }
};


const validateSelectedFileType = () => {
  if (!selectedFileType.value) {
    errorResultsSelectMessage.value = 'Vyber typ souboru.';
    isResultsFileValid.value = false;
  } else {
    errorResultsSelectMessage.value = ''; // Chyba je odstraněna
    isResultsFileValid.value = !!uploadedResultsFile.value; // Validace závisí na obou polích
  }
};



const isResultsFileValid = ref(false);
const errorResultsSelectMessage = ref('');

const handleResultsFileUpload = (event) => {
  errorResultsMessage.value = '';
  successResultsMessage.value = '';
  const file = event.target.files[0];

  if (file && file.name) {
    const validExtensions = ['.csv', '.xml'];
    const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

    if (validExtensions.includes(fileExtension)) {
      uploadedResultsFile.value = file; // Soubor je validní
    } else {
      uploadedResultsFile.value = null;
      errorResultsMessage.value = 'Vyberte platný soubor s příponou .csv nebo .xml.';
      return; // Ukončíme kontrolu, protože soubor není validní
    }
  } else {
    uploadedResultsFile.value = null;
    errorResultsMessage.value = 'Musíš vybrat soubor.';
    return; // Ukončíme kontrolu, protože soubor není nahrán
  }

  validateSelectedFileType(); // Kontrola, zda je typ vybrán
};

watch(selectedFileType, (newVal) => {
  console.log('selectedFileType changed:', newVal);
  validateSelectedFileType();
});


// Objekt pro uložení barev přiřazených řádkům při jejich inicializaci
const rowResultsColors = ref({});

const setResultsRowProps = ({ item }) => {
  const itemIndex = item.competitor_index || ''; // Získání hodnoty indexu
  const itemName = `${item.last_name.toLowerCase()} ${item.first_name.toLowerCase()}`; // Kombinace příjmení a jména

  // Pokud již existuje barva pro daný řádek, vraťte ji
  if (rowResultsColors.value[itemIndex] || rowResultsColors.value[itemName]) {
    return { class: rowResultsColors.value[itemIndex] || rowResultsColors.value[itemName] };
  }

  let rowResultsClass = '';

  if (itemIndex) {
    // Pokud je přítomný index, kontrolujeme podle indexu
    if (resultsEntryOk.value.some(okItem => okItem.competitor_index === itemIndex)) {
      rowResultsClass = 'bg-ok';
    } else if (resultsEntryCreate.value.some(createItem => createItem.competitor_index === itemIndex)) {
      rowResultsClass = 'bg-create';
    } else if (resultsEntryEmpty.value.some(emptyItem => emptyItem.competitor_index === itemIndex)) {
      rowResultsClass = 'bg-empty';
    }
    rowColors.value[itemIndex] = rowResultsClass; // Uložení barvy pro tento řádek podle indexu
  } else {
    // Pokud index není přítomný, kontrolujeme podle kombinace příjmení a jména
    if (resultsEntryOk.value.some(okItem => `${okItem.last_name.toLowerCase()} ${okItem.first_name.toLowerCase()}` === itemName)) {
      rowResultsClass = 'bg-ok';
    } else if (resultsEntryCreate.value.some(createItem => `${createItem.last_name.toLowerCase()} ${createItem.first_name.toLowerCase()}` === itemName)) {
      rowResultsClass = 'bg-create';
    } else if (resultsEntryEmpty.value.some(emptyItem => `${emptyItem.last_name.toLowerCase()} ${emptyItem.first_name.toLowerCase()}` === itemName)) {
      rowResultsClass = 'bg-empty';
    }
    rowResultsColors.value[itemName] = rowResultsClass; // Uložení barvy pro tento řádek podle kombinace příjmení a jména
  }

  return { class: rowResultsClass };
};



const openEditResultDialog = (result) => {
  selectedResult.value = {
    ...result,
  };
  dialogEditResult.value = true;
};


// const saveEditResult = () => {

//   let resultIndex = -1;

// // Vyhledání podle indexu, pokud existuje
// if (selectedResult.value.competitor_index) {
//   resultIndex = combinedResults.value.findIndex(
//     (item) => item.competitor_index === selectedResult.value.competitor_index
//   );
// }

// // Pokud index neexistuje nebo nebyl nalezen, vyhledání podle kombinace jména a příjmení
// if (resultIndex === -1) {
//   resultIndex = combinedResults.value.findIndex(
//     (item) =>
//       item.first_name.toLowerCase() === selectedResult.value.first_name.toLowerCase() &&
//       item.last_name.toLowerCase() === selectedResult.value.last_name.toLowerCase()
//   );
// }

// // Pokud byl záznam nalezen, aktualizujeme data
// if (resultIndex !== -1) {
//   combinedResults.value[resultIndex] = { ...selectedResult.value };

//   // Aktualizace jednotlivých seznamů
//   resultsEntryOk.value = combinedStarts.value.filter((item) =>
//     resultsEntryOk.value.some(
//       (okItem) => okItem.competitor_index === item.competitor_index ||
//       (okItem.first_name.toLowerCase() === item.first_name.toLowerCase() &&
//        okItem.last_name.toLowerCase() === item.last_name.toLowerCase())
//     )
//   );

//   resultsEntryCreate.value = combinedResults.value.filter((item) =>
//     resultsEntryCreate.value.some(
//       (createItem) => createItem.competitor_index === item.competitor_index ||
//       (createItem.first_name.toLowerCase() === item.first_name.toLowerCase() &&
//        createItem.last_name.toLowerCase() === item.last_name.toLowerCase())
//     )
//   );

//   resultsEntryEmpty.value = combinedResults.value.filter((item) =>
//     resultsEntryEmpty.value.some(
//       (emptyItem) => emptyItem.competitor_index === item.competitor_index ||
//       (emptyItem.first_name.toLowerCase() === item.first_name.toLowerCase() &&
//        emptyItem.last_name.toLowerCase() === item.last_name.toLowerCase())
//     )
//   );
// }

// // Zavření dialogu po uložení
// dialogEditResult.value = false;
// };



const openDeleteResultDialog = (result) => {
  selectedResult.value = result;
  dialogDeleteResult.value = true;
};

const deleteResult = () => {
  let resultIndex = combinedResults.value.findIndex(
  (item) => item.competitor_index === selectedResult.value.competitor_index
);
  // Pokud nenalezen podle `competitor_index`, hledáme podle jména a příjmení
  if (resultIndex === -1) {
    resultIndex = combinedResults.value.findIndex(
      (item) =>
        item.first_name.toLowerCase() === selectedResult.value.first_name.toLowerCase() &&
        item.last_name.toLowerCase() === selectedResult.value.last_name.toLowerCase()
    );
  }
  // Smazání, pokud byl záznam nalezen
  if (resultIndex !== -1) {
    combinedResults.value.splice(resultIndex, 1);
    resultsEntryOk.value = combinedResults.value.filter(
      (item) => resultsEntryOk.value.some(okItem => okItem.competitor_index === item.competitor_index)
    );
    resultsEntryCreate.value = combinedResults.value.filter(
      (item) => resultsEntryCreate.value.some(createItem => createItem.competitor_index === item.competitor_index)
    );
    resultsEntryEmpty.value = combinedResults.value.filter(
      (item) => resultsEntryEmpty.value.some(emptyItem => emptyItem.competitor_index === item.competitor_index)
    );
  }
  dialogDeleteResult.value = false;
};



// odeslání JSON pro uložení startů
const saveResultsData = async () => {
  loadingSubmit.value = true;
  try {
    const payload = {
      results: combinedResults.value, // Obsahuje výsledky
      category_properties: categoryProperties.value, // Obsahuje vlastnosti kategorií
    };

    await apiClient.post(
      `results/?race_id=${currentRaceId.value}&valid=True`,
      JSON.stringify(payload),  // Převod dat na JSON formát
      {
        headers: {
          'Content-Type': 'application/json',  // Nastavení správné hlavičky
        },
      }
    );
    successMessage.value = 'Vysledky byly úspěšně nahrány';
    errorResultsSendMessage.value = ""
    dialogAddResults.value = false;  // Zavřít dialog po úspěšném odeslání
    combinedResults.value = [];  // Vynulovat obsah combinedResults po úspěšném uložení
    rowResultsColors.value = {}; // Resetování barev při úspěšném odeslání
    categoryProperties.value = {}; // resetování kategorií
    successResultsMessage.value = '';
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy

    fetchEntries();
    fetchResults();

  } catch (error) {
    console.error('Chyba při odesílání dat:', error);

    // Kontrola kódu 406 a zobrazení specifické chyby
    if (error.response && error.response.status === 406 && error.response.data && Array.isArray(error.response.data.error)) {
      errorResultsSendMessage.value = error.response.data.error; // Přiřadit celé pole chybových zpráv
      console.log('Error message:', errorResultsSendMessage.value); // Debugging
    } else {
      errorResultsSendMessage.value = ['Data pro startovku se nepodařilo odeslat.']; // Přiřadit jako pole
      console.log('Error message:', errorResultsSendMessage.value);
    }
  } finally {
      loadingSubmit.value = false;  // Ukončení načítání po zpracování odpovědi
    }
};


const dialogDeleteAllResults = ref(false);

// Funkce pro otevření dialogu pro smazání všech results
const openDeleteAllResultsDialog = () => {
  dialogDeleteAllResults.value = true;
};

// Funkce pro mazání všech results
const deleteAllResults = async () => {
  try {
    await apiClient.delete(`/results/?race_id=${currentRaceId.value}`);
    dialogDeleteAllResults.value = false;
    successMessage.value = 'Všechny výsledky byly úspěšně smazány.';
    fetchResults(); // Obnovit list výsledků po smazání
  } catch (error) {
    console.error('Chyba při mazání výsledků:', error);
    errorMessage.value = 'Nepodařilo se smazat výsledky.';
  }
};


/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////





</script>



<style scoped>

/** Zmenšení mezer mezi jednotlivými prvky item listu */
#app .v-list-item--density-default {
  min-height: 35px;
}

td {
  min-width: 160px;
}

.dialog-over-dialog .v-dialog__content {
  z-index: 202 !important; /* Standardní z-index pro dialogy Vuetify je 200 */
}

/* zmenšení margin, aby se do okna vešel rám oken */
.v-row {
  margin:-10px;
}

.background-color-1 {
  background-color: #ffffff; /* Light gray background for even days */
}

.background-color-2 {
  background-color: #f6f9ff; /* Light blue background for odd days */
}

@media (min-width: 600px) {
    .v-btn {
      width: auto !important;
    }
  }


  .first-row {
  background-color: #e8efff;
  border-radius: 4px;
  padding: 0 5px 0 5px;
}

/* Stylování vlastího btn pro odkaz na správu všech kategorií hromadně */
.custom-btn {
  display: inline-block;
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  line-height: 24px;
  color: white;
  background-color:  rgb(24, 103, 192); /* Barva pozadí, může být změněna */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none; /* Odstraní podtržení odkazu */
  white-space: normal; /* Umožňuje zalamování textu */
  word-wrap: break-word; /* Zajišťuje, že dlouhá slova se zalamují */
}

.custom-btn:hover {
  background-color: #1565C0; /* Barva pozadí při najetí myší, může být změněna */
}

:deep(.bg-ok) {
  background-color: #e8f5e9!important; /* Světle zelená */
}

:deep(.bg-create) {
  background-color: #fff3e0!important; /* Světle oranžová */
}

:deep(.bg-empty) {
  background-color: #ffebee!important; /* Světle červená */
}


/* .v-input {
  max-height: 55px; /* Nastavení minimální výšky */
 /* padding: 4px 12px; /* Nastavení paddingu */
/*} */
</style>